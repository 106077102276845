import actions from './actions';
//import initialState from '../../demoData/header-search.json';
import staticData from '../../staticFiles/category-search.json';

import userservices from '../../services/userServices';//

const { searchHeaderBegin, searchHeaderSuccess, searchHeaderErr } = actions;

const headerSearchAction = (org_flag) => {
  return async (dispatch) => {
    try {
      const category = staticData;

      dispatch(searchHeaderBegin());
      const organizations = await userservices.searchOrganizationList(org_flag);
      if(org_flag == true){
        console.log("org_flag :",org_flag);
        const data = [...organizations];
        dispatch(searchHeaderSuccess(data));
      }else{
        const data = [...category, ...organizations];
        dispatch(searchHeaderSuccess(data));
      }            
      //console.log('headerSearchAction',data);
      // const data1 = initialState.filter((item) => {
      //   return item.title.startsWith(searchData);
      // });
      //console.log('searchHeaderSuccess1',data1);
      //console.log('searchHeaderSuccess',data);
      
    } catch (err) {
      dispatch(searchHeaderErr(err));
    }
  };
};

export { headerSearchAction };
