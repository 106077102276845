const actions = {
    LIST_ORGANIZATION_BEGIN: 'LIST_ORGANIZATION_BEGIN',
    LIST_ORGANIZATION_SUCCESS: 'LIST_ORGANIZATION_SUCCESS',
    LIST_ORGANIZATION_ERR: 'LIST_ORGANIZATION_ERR',

    CATEGORY_ORGANIZATION_BEGIN: 'CATEGORY_ORGANIZATION_BEGIN',
    CATEGORY_ORGANIZATION_SUCCESS: 'CATEGORY_ORGANIZATION_SUCCESS',
    CATEGORY_ORGANIZATION_ERR: 'CATEGORY_ORGANIZATION_ERR',

    listOrganizationsBegin: () => {
      return {
        type: actions.LIST_ORGANIZATION_BEGIN,
      };
    },
  
    listOrganizationsSuccess: data => {
      return {
        type: actions.LIST_ORGANIZATION_SUCCESS,
        data,
      };
    },
  
    listOrganizationsSendErr: err => {
      return {
        type: actions.LIST_ORGANIZATION_ERR,
        err,
      };
    },

    categoryOrganizationsBegin: () => {
      return {
        type: actions.LIST_ORGANIZATION_BEGIN,
      };
    },
  
    categoryOrganizationsSuccess: data => {
      return {
        type: actions.CATEGORY_ORGANIZATION_SUCCESS,
        data,
      };
    },
  
    categoryOrganizationsSendErr: err => {
      return {
        type: actions.CATEGORY_ORGANIZATION_ERR,
        err,
      };
    },

  };

  
  
  export default actions;