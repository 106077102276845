import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover, notification } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../styled';
import { useHistory } from 'react-router-dom';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';

function ContactTable({ contactData }) {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const history = useHistory();
  const usersTableData = [];


  if (0 < contactData.length && contactData != null) {
    contactData.map((val) => {
      const { id, name, email, phone, message, status, created_at } = val;      
      return usersTableData.push({
        key: id,
        name: name,
        email: email,
        phone: phone,
        message:message,
        created_at: (<p><FeatherIcon icon="calendar" size={14} /> {created_at}</p>)        
      });
    });
  }


  const usersTableColumns = [
    {
      title: 'Name',
      align: 'center',
      dataIndex: 'name',
      key: 'name',
    },    
    {
      title: 'Email',
      align: 'center',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      align: 'center',
      dataIndex: 'phone',
      key: 'phone',
    },    
    {
      title: 'Message',
      align: 'center',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Create Date',
      align: 'center',
      dataIndex: 'created_at',
      key: 'created_at',
    }
  ];

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  

  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={usersTableData}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: 5,
              total: usersTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>

    </Cards>

  );
}


export default ContactTable;
