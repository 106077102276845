import { Button, Col, Row, Modal, Tooltip, notification } from 'antd'
import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

//import { CopyToClipboard } from 'react-copy-to-clipboard';
//import notes_icon from '../../static/img/leading-icon.svg';
//import messages from '../../config/constants/messages';
//import leftArrow from '../../static/img/icon/left-arrow.svg';


const EventDetails = ({match}) => {
    const role = "Home"

    const history = useHistory();

    const [Image, setImage] = useState('');

    const [Name, setName] = useState('');
    const [PublishedName, setPublishedName] = useState('');
    const [Desc, setDesc] = useState('');
    const [address, setAddress] = useState('');
    const [education_Url, setUrl] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');

    const [DateRange, setDateRange] = useState('');
    const [isActive, setisActive] = useState(false);
    const { url } = useParams();


    const [artistDetailsModalVisible, setArtistDetailsModalVisible] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        //alert(match.params.id);
        axios.get(`${process.env.REACT_APP_API_URL}/get-current-event/${match.params.id}`)
            .then(function (response) {
                if (response.data.length > 0) {
                    setisActive(response.data[0].status)
                    setName(response.data[0].title)
                    setDateRange(response.data[0].date_active+' - '+response.data[0].date_expiry)
                    setDesc(response.data[0].description)
                    setImage(response.data[0].image)
                    setAddress(response.data[0].address)
                    /*
                    setMetaTitle(response.data[0].meta_title)
                    setMetaDescription(response.data[0].meta_description)
                    setMetaKeywords(response.data[0].meta_keywords)
                    */
                    setUrl(response.data[0].event_id)                    
                    setPublishedName(response.data[0].name);

                } else {
                    setisActive(false)
                }


            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })

    }, [])

    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);

    const shareLink = () => {
        notification.success({
            message: ""
        });
    };
    const gotoback = () => {
        history.push('/events');
    }



    return (
        <div style={{
            width: '100%',
        }}>
            <div>                
                <div style={{ marginTop: 20, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={24} sm={24} md={2} lg={2}>
                            <Button style={{ cursor: 'pointer', border: '1px solid #5f63f2', color: '#5f63f2', background: 'rgb(255, 255, 255)', borderRadius: 4 }} size='default' onClick={gotoback}>
                                {/* <img className="like-dislike" src={leftArrow} style={{ width: '14px', height: '14px' }} /> */} Go back
                            </Button>
                        </Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            {
                                isActive == 1 ?
                                    <>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 0 }} align="center">{Name}</p>
                                        </Row>
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">
                                                <div class="entry-meta">
                                                    Published by <span class="entry-author">{PublishedName} </span>
                                                    • 
                                                    <span class="entry-date">{' '+DateRange} </span>
                                                </div>
                                                <div class="entry-meta">
                                                    <span class="entry-date"><FeatherIcon icon="map-pin" size={14} />{' '}{address} </span>
                                                </div>
                                                <br />
                                                <Tooltip placement="bottomRight" title="Click to share">
                                                    {/* <CopyToClipboard text={`https://stoodio.art/education/` + education_Url}>
                                                        <img src={notes_icon} onClick={() => { shareLink() }} style={{ width: '40px', height: '40px', margin: '10px' }} />
                                                    </CopyToClipboard> */}
                                                </Tooltip>
                                            </p>
                                        </Row>

                                        <Row style={{ justifyContent: 'center' }}>
                                            {
                                                Image != '' ?
                                                    <img
                                                        src={Image}
                                                        alt="Art-Work"
                                                        style={{
                                                            borderRadius: 8,
                                                            minHeight: '250px',
                                                            width: '100%',
                                                            maxHeight: 400,
                                                            maxWidth: 600
                                                        }}
                                                    />
                                                    :
                                                    <img
                                                        src='https://stoodio.art/static/media/stoodiologo.175eeac4.svg'
                                                        alt="Art-Work"
                                                        style={{
                                                            borderRadius: 8,
                                                            height: '450px',
                                                            maxWidth: 700
                                                        }}

                                                    />
                                            }
                                        </Row>
                                        <br /><br />
                                        <Row style={{ justifyContent: 'center' }}>
                                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: Desc != "" ? Desc : "" }}></p>
                                        </Row>


                                    </>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                        {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Not Found</p> */}
                                    </Row>
                            }

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>
                

            </div>
        </div >
    )
}

export default EventDetails