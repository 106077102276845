import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';
import useInput from '../../profile/authentication/overview/useInput';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';



const { TextArea } = Input;

const CreateEvent = ({ visible, onCancel }) => {

  const dispatch = useDispatch();

  const isFbAuthenticate = useSelector(state => {

    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });

  let address = useInput();


  const [form] = Form.useForm();
  const formData = new FormData();
  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    title: '',
    description: '',
    link: '',
    image: '',
    status: '',
    isLoading: false
  });


  const [image, setImage] = useState('');

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);




  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [start_time, setStartTime] = useState(null);
  const [end_time, setEndTime] = useState(null);


  const fileobj = [];


  const handleOk = (values) => {
    if (address.addressData.place_name == undefined) {
      message.warning("Please input your event location", process.env.REACT_APP_NOTIFICATION_DELAY);
      return false
    }

    const date_active = moment(values.date_active).format('YYYY-MM-DD');
    const date_expiry = moment(values.date_expiry).format('YYYY-MM-DD');
    const start_time1 = start_time != "" && moment(start_time).format(format) != "Invalid date" ? moment(start_time).format(format) : "";
    const end_time1 = end_time != "" && moment(end_time).format(format) != "Invalid date" ? moment(end_time).format(format) : "";
    let post_data = {
      uid: isFbAuthenticate.isFbAuthenticate,
      ...values,
      date_active: date_active,
      date_expiry: date_expiry,
      start_time: start_time1,
      end_time: end_time1,
      address: address.addressData.place_name != undefined ? address.addressData.place_name : null,
      address_coordinate: address.addressData.center != undefined ? address.addressData.center : null,
    };
    console.log(values);
    console.log(start_time1, end_time1);
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });


    console.log(post_data);

    const formData = new FormData();
    if (image) {
      formData.append('files', image);
    }

    formData.append('data', JSON.stringify(post_data))

    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    axios.post(`${process.env.REACT_APP_API_URL}/create-event`, formData)
      .then((response) => {
        if (response.data.status == true) {
          alert("The event create successfully.");
          //window.location.reload();
          onCancel();
        } else {
          setButton(false);
          setLoading(false);
          alert("Uanble to update the event");
        }
      })
      .catch((error) => {
        console.log("Error :", error)
      })

  };




  const handleCancel = () => {
    onCancel();
  };

  const handleImageChange = (e) => {
    // console.log('this is e')
    console.log("e.target.files", e.target.files);
    //console.log(e.target.name)
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          message.error('Invalid image format', process.env.REACT_APP_NOTIFICATION_DELAY)
          return false;
        }
      }
      setImage(e.target.files[0]);
    } else {
      setImage("");
    }
  }




  return (

    <>

      <Modal
        type={state.modalType}
        title="Create Event"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          <>
            <FormValidationWrap>
              <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">
                <Form.Item name="title" label="Title"
                  rules={[{ required: true, message: 'Please input event title' }]}

                >
                  <Input.TextArea rows={2} placeholder="" name="title" />
                </Form.Item>

                <Form.Item name="description" label="Description"
                  rules={[{ required: true, message: 'Please input event description' }]}
                >
                  <TextArea rows={3} type='text' name='description' />
                </Form.Item>

                <Form.Item name="image" label="Image URL"

                >
                  <Input type='file' name='image' onChange={handleImageChange} />
                </Form.Item>


                <Row gutter={15}>
                  <Col md={12} xs={24} sm={12} >
                    <Form.Item name="date_active" label="Start Date"
                      rules={[{ required: true, message: 'Please input start date' }]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24} sm={12} >
                    <Form.Item name="start_time" label="Start Time"
                      rules={[{ required: true, message: 'Please input start time' }]}
                    >
                      <TimePicker
                        //value={start_time != '' && moment(start_time, format)}
                        //defaultValue={start_time != '' && moment(start_time, format)}
                        use12Hours
                        placeholder=" Select time"
                        format={format}
                        onChange={(time, timeString) => { setStartTime(time != null ? time : "") }}
                        allowClear
                      />
                    </Form.Item>

                  </Col>
                </Row>

                <Row gutter={15}>
                  <Col md={12} xs={24} sm={12} >
                    <Form.Item name="date_expiry" label="End Date"
                      rules={[{ required: true, message: 'Please input end date' }]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24} sm={12} >
                    <Form.Item name="end_time" label="End Time"
                      rules={[{ required: true, message: 'Please input end time' }]}
                    >
                      <TimePicker
                        //value={start_time != '' && moment(start_time, format)}
                        //defaultValue={start_time != '' && moment(start_time, format)}
                        use12Hours
                        placeholder=" Select time"
                        format={format}
                        onChange={(time, timeString) => { setEndTime(time != null ? time : "") }}
                        allowClear
                      />
                    </Form.Item>
                  </Col>
                </Row>



                <Form.Item name="tags" label="Tags"
                  rules={[{ required: true, message: 'Please input tags' }]}
                >
                  <Input placeholder="tags" name="tags" />
                </Form.Item>

                <Form.Item name="location" label="Location">
                  <Input
                    placeholder="Event Address"
                    {...address}
                    isTyping={address.value !== ""}
                    style={{ marginBottom: 0, borderColor: '#e4e7f0' }}
                  />
                  {address.suggestions?.length > 0 && (
                    <SuggestionWrapper>
                      {address.suggestions.map((suggestion, index) => {
                        return (
                          <Suggestion
                            key={index}
                            onClick={() => {
                              address.setValue(suggestion.place_name);
                              address.setSuggestions([]);
                              address.setAddressData(suggestion)
                            }}
                          >
                            {suggestion.place_name}
                          </Suggestion>
                        );
                      })}
                    </SuggestionWrapper>
                  )}
                </Form.Item>


                <Form.Item>

                  <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                    Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                  </Button>
                  {' '}
                  <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </FormValidationWrap>
          </>

        </div>
      </Modal>
    </>
  );
};

CreateEvent.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateEvent;


const SuggestionWrapper = styled.div`
  background: #ecedee;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
  width:100%;
`;

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
  text-align:left;
`;

