import axios from 'axios';


const SignUp = async (data) => {
    console.log("axios data : ", data);
    return await axios.post(`${process.env.REACT_APP_API_URL}}/signup`, data)
        .then(function (response) {
            //StoreCalculation(data.email);
            console.log(response);
        })
        .catch(function (error) {
            // handle your error
            console.log(error);
        });
}

const OrganizationList = async (uid) => {
    let url = ''
    if (uid != undefined && uid == process.env.REACT_APP_ADMIN_UID) {
        url = `${process.env.REACT_APP_API_URL}/organization-list/all`;
    } else {
        url = `${process.env.REACT_APP_API_URL}/organization-list/not`
    }
    const response = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();

    return data;
}

const categoryOrganization = async (uid, category) => {
    let url = ''
    if (uid != undefined && uid == process.env.REACT_APP_ADMIN_UID) {
        url = `${process.env.REACT_APP_API_URL}/organization-category/all/${category}`;
    } else {
        url = `${process.env.REACT_APP_API_URL}/organization-category/not/${category}`
    }
    const response = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });
    const data = await response.json();
    return data;
}

const updateUID = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/update-uid`, data)
        .then(function (response) {
            //StoreCalculation(data.email);
            console.log(response);
        })
        .catch(function (error) {
            // handle your error
            console.log(error);
        });
}

const searchOrganizationList = async (only_org) => {
    //
    let url = `${process.env.REACT_APP_API_URL}/search-organization/${only_org}`;
    const response = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });
    const data = await response.json();
    return data;
}

const SnippetsList = async (uid) => {
    let url = ''
    if (uid != undefined && uid == process.env.REACT_APP_ADMIN_UID) {
        url = `${process.env.REACT_APP_API_URL}/snippet-list`;
    }
    const response = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();

    return data;
}

const createPaymentIntent = async (price) => {
    let subtotal_data = { price: price };
    
    return await axios.post(`${process.env.REACT_APP_API_URL}/payment`, subtotal_data)
        .then(function (response) {
            if (response.status == 200 && response.data.clientSecret != "" && response.data.status == true) {
                return response.data.clientSecret;
            } else {
                return "";
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

const createToken = async (data) => {
    let actual_data = data;
    
    return await axios.post(`${process.env.REACT_APP_API_URL}/create-token`, actual_data)
        .then(function (response) {
            if (response.status == 200 && response.data.clientSecret != "" && response.data.status == true) {
                return response.data.clientSecret;
            } else {
                return "";
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}


const OrganizationFutureList = async () => {
    let url = `${process.env.REACT_APP_API_URL}/organization-list/future`;
    const response = await fetch(url, {
        method: "GET", // or 'PUT'
        headers: {
            "Content-Type": "application/json",
        }
    });

    const data = await response.json();

    return data;
}

const userServices = {
    SignUp,
    OrganizationList,
    categoryOrganization,
    createToken,
    searchOrganizationList,
    OrganizationFutureList,
    updateUID,
    SnippetsList,
    createPaymentIntent
};

export default userServices;