import React, { useEffect, useRef, useState } from 'react';

function ImageCombiner({ imageUrls }) {
    const canvasRef = useRef(null);
    const [combinedImageUrl, setCombinedImageUrl] = useState(null); // State to store the generated image URL

    useEffect(() => {
        combineImages();
    }, []);
    const combineImages = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
  
      const canvasHeight = 150; // Fixed height for the images
      const canvasWidth = imageUrls.length * 200; // Width based on the number of images
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
  
      let loadedImages = 0;
  
      imageUrls.forEach((url, index) => {
        const img = new Image();
        img.crossOrigin = "anonymous"; // Handle CORS for external images
        img.src = url;
  
        img.onload = () => {
          // Calculate position of the image in the row
          let x = index * 130; // Move horizontally for each image
          // Draw the image at its fixed size (200x200)
          ctx.drawImage(img, x, 50, 100, 50);
          loadedImages++;
          if (loadedImages === imageUrls.length) {
            // All images have been loaded and drawn
            generateCombinedImage();
          }
        };
      });
    };

    const generateCombinedImage = () => {
        const canvas = canvasRef.current;
        const image = canvas.toDataURL("image/png");
        setCombinedImageUrl(image);  // Set the combined image URL to state
    };

    return (
        <div>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas> {/* Hide the canvas */}

            {/* Show the combined image in HTML */}
            {combinedImageUrl && (
                <div>
                    <img src={combinedImageUrl} alt="Combined Image"
                        style={{
                            // border: '1px solid black',
                            width: '100%',
                            maxHeight: '250px',
                            minHeight: '150px'
                        }} />
                </div>
            )}
        </div>
    );
}

export default ImageCombiner;
