import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
  };

  const {
    LIST_ORGANIZATION_BEGIN,
    LIST_ORGANIZATION_SUCCESS,
    LIST_ORGANIZATION_ERR,

    CATEGORY_ORGANIZATION_BEGIN,
    CATEGORY_ORGANIZATION_SUCCESS,
    CATEGORY_ORGANIZATION_ERR,

  } = actions;  
 
  const organizationsReducer = (state = initialState , action) =>{

    const { type, data, err } = action;
    switch (type) {
        case LIST_ORGANIZATION_BEGIN:
        return {
            ...initialState,
            loading: true,
        };

        case LIST_ORGANIZATION_SUCCESS:
        return {
            ...initialState,
            data,
            error: false,
            loading: false,
        };

        case LIST_ORGANIZATION_ERR:
        return {
            ...initialState,
            error: err,
            loading: false,
        };

        case CATEGORY_ORGANIZATION_BEGIN:
        return {
            ...initialState,
            loading: true,
        };

        case CATEGORY_ORGANIZATION_SUCCESS:
        return {
            ...initialState,
            data,
            error: false,
            loading: false,
        };

        case CATEGORY_ORGANIZATION_ERR:
        return {
            ...initialState,
            error: err,
            loading: false,
        };


        default:
      return state;
    }

  }

  export default organizationsReducer;