import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, message, Tabs } from 'antd';
import { PageHeader } from '../../components/page-headers/page-headers';
import { Main } from '../styled';
import { useSelector } from 'react-redux';
import { Tab } from '../../components/tabs/tabs';
import { Button } from '../../components/buttons/buttons';
import { Cards } from '../../components/cards/frame/cards-frame';
import axios from 'axios';


function InviteOrganization() {

  const { TabPane } = Tabs;

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });



  const [settingform] = Form.useForm();

  /*
  useEffect(() => {
    if (data == null) {
      axios.get(`${process.env.REACT_APP_API_URL}/get-email-credentials/${isAuthenticate}`)
        .then((response) => {
          setData(response.data.data);
          setIsVisiable(response.data.status);
        })
        .catch((error) => {
          console.log("Error", error)
        });

    }
  }, [data,isVisiable]);
  */




  const handleSubmit = values => {

    var actualObj = { ...values, uid: isAuthenticate };

    const promise = new Promise((resolve, reject) => {
      let email_data = { email: values.invite_organization_email };
      axios.post(`${process.env.REACT_APP_API_URL}/check-email`, email_data)
        .then(function (response) {
          if (response.data.status == true) {
            resolve(true);
          } else {
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    promise.then((d) => {

      axios.post(`${process.env.REACT_APP_API_URL}/invite-organization`, actualObj)
        .then(function (response) {
          if (response.status == 200) {
            alert(response.data.message)
            window.location.reload();
          } else {
            alert("Please try again")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };


  return (
    <>
      <PageHeader
        title="Invite Organization"
      />

      <Main>

        <Row gutter={25}>
          <Cards headless>
            <Form name="sDash_validation-form" form={settingform} layout="vertical" onFinish={handleSubmit}>
              <Col md={24} lg={24} xxl={24} xs={24}>
                <Form.Item
                  name="invite_organization_name"
                  label="Organization Name"
                  rules={[{ required: true, message: 'Organization name required!' }]}
                >
                  <Input placeholder="Enter organization name" />
                </Form.Item>
              </Col>
              <Col md={24} lg={24} xxl={24} xs={24}>
                <Form.Item
                  name="invite_organization_email"
                  label="Organization Email"
                  rules={[{ required: true, message: 'Organization email required!' }]}
                >
                  <Input placeholder="Enter organization email" type="email" />
                </Form.Item>
              </Col>
              <Button htmlType="submit" className="btn-proceed" type="primary" size="large" style={{ marginTop: '25px' }}>
                Invite
              </Button>
            </Form>

          </Cards>
        </Row>
      </Main>
    </>
  );
}

export default InviteOrganization;
