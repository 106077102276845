import { Input, Col, Row, Space, Spin, Button, Typography, Pagination } from 'antd'
import FeatherIcon from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import NO_IMAGE from '../../../../src/static/img/No_image_available.png'
import VideoCard from '../../../../src/container/home/VideoCard';


const projects = [
    {
        title: "Self-balancing 2-wheeled electric car",
        description: "Proof of demand; $31.9M projected revenue (at $32,000 MSRP) from 1000+ preorders w/$750 avg deposit",
        image: "https://via.placeholder.com/300x140",
        status: "trending",
        userImage: "https://uploads.wefunder.com/uploads/company_role/custom_photo/4258720/square_blob.jpg?1715027349"
    },
    {
        title: "A breakthrough treatment for chronic pain & neuromuscular disorders",
        description: "25,000+ patients treated across 50+ hospital channel partners",
        image: "https://via.placeholder.com/300x140",
        status: "trending",
        userImage: "https://via.placeholder.com/40"
    },
    {
        title: "Life-saving injections as easy as pushing a button",
        description: "Led by YC-backed rocket scientists with funding from VCs Safar Partners & Gaingels, 21 patents awarded",
        image: "https://via.placeholder.com/300x140",
        status: "almostFunded",
        userImage: "https://via.placeholder.com/40"
    },
    {
        title: "Patented, research-backed stress-relief device creating a paradigm shift in the $5T wellness market",
        description: "Proof of demand; $31.9M projected revenue (at $32,000 MSRP) from 1000+ preorders w/$750 avg deposit",
        image: "https://via.placeholder.com/300x140",
        status: "trending",
        userImage: "https://via.placeholder.com/40"
    },
    {
        title: "Impacting billions by fighting inflammation at the source",
        description: "$5.32M revenues in 2023, Sold in 50+ countries25,000+ patients treated across 50+ hospital channel partners",
        image: "https://via.placeholder.com/300x140",
        status: "trending",
        userImage: "https://via.placeholder.com/40"
    },
    {
        title: "Wrist Worn Keyboard, AirMouse & Controller",
        description: "Our first product has over 40,000 customers, and a 4+ star Amazon rating patents awarded $5.32M revenues in 2023, Sold in 50+ countries",
        image: "https://via.placeholder.com/300x140",
        status: "almostFunded",
        userImage: "https://uploads.wefunder.com/uploads/company_role/custom_photo/4258720/square_blob.jpg?1715027349"
    }
];


const EventDataList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [newsData, setNewsData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-events/null/all`)
            .then(function (response) {
                console.log("response", response)
                if (response.data.length > 0) {
                    var evdata = []
                    response.data.forEach(element => {
                        evdata.push(
                            {
                                event_id:element.event_id,
                                title: element.title.slice(0,50),
                                description: element.description.slice(0,120),
                                image: element.image,
                                userImage: "https://uploads.wefunder.com/uploads/company_role/custom_photo/4258720/square_blob.jpg?1715027349"
                            }
                        )
                    });
                    setNewsData(evdata)
                    console.log(evdata)
                    setsearchData(evdata)
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);
    // Define the getColumns function before using it
    const getColumns = (width) => {
        if (width >= 1300) {
            return 3; // Large devices
        } else if (width >= 950) {
            return 2; // Medium devices
        } else {
            return 1; // Small devices
        }
    };

    // Initialize columns state with the current window width
    const [itemsPerPage, setItemsPerPage] = useState(getColumns(window.innerWidth));

    useEffect(() => {
        const handleResize = () => {
            setItemsPerPage(getColumns(window.innerWidth));
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const redirectURL = (url) => {
        //history.push(url);
        window.open(url, '_blank');
    };

    const searchRecords = (searchText) => {
        console.log("searchText:", searchText);
        // Filter newsData based on searchText
        const filteredData = newsData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.location && item.location.toLowerCase().includes(searchText.toLowerCase())) ||
            item.link.toLowerCase().includes(searchText.toLowerCase())
        );

        console.log("Filtered Data:", filteredData);
        // Update the searchData state with filtered data
        setsearchData(filteredData);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentProjects = searchData.slice(startIndex, startIndex + itemsPerPage);
    console.log("currentProjects : ",currentProjects);

    const formatDateRange = (dateActive, dateExpiry) => {
        const startDate = new Date(dateActive);
        const endDate = new Date(dateExpiry);

        const startMonth = startDate.toLocaleString('default', { month: 'long' });
        const endMonth = endDate.toLocaleString('default', { month: 'long' });

        const startDay = startDate.getDate();
        const endDay = endDate.getDate();

        const endDayWithSuffix = addDaySuffix(endDay);

        return `${startMonth} ${startDay} - ${endDayWithSuffix}${endMonth === startMonth ? '' : ' ' + endMonth}`;
    };

    const addDaySuffix = (day) => {
        if (day >= 11 && day <= 13) {
            return day + 'th';
        }
        switch (day % 10) {
            case 1:
                return day + 'st';
            case 2:
                return day + 'nd';
            case 3:
                return day + 'rd';
            default:
                return day + 'th';
        }
    };


    return (
        <div style={{
            width: '100%',
        }}>

            <div>
                <div style={{ marginTop: 10, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            {/* <Row justify="center" style={{ margin: '10px 0' }}>
                                <Col span={24} md={12}>
                                    <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 25 }} >Events</p>
                                </Col>
                            </Row> */}
                            {/* {
                                [...searchData].length > 0 ?
                                    <div>
                                        <Row>
                                            {
                                                [...searchData].map((item) => {
                                                    console.log("item :", item);
                                                    if (item) {
                                                        let height = 25;
                                                        let url_link = item.link;
                                                        let imagesURL = item.image;
                                                        let logo = '';
                                                        if (item.logo != null) {
                                                            logo = process.env.REACT_APP_LOGO_URL + item.logo
                                                        }
                                                        const formattedDateRange = formatDateRange(item.date_active, item.date_expiry);
                                                        return (
                                                            imagesURL &&
                                                            <Row style={{ width: '100%', marginBottom: 20 }}>
                                                                <Col xs={24} sm={24} md={10} lg={13} xl={13} className='add-padding-desktop' style={{ textAlign: 'right', padding: '30px 2px' }}>
                                                                    <>
                                                                        <a href={url_link} style={{ background: 'white', justifyContent: 'right', display: 'flex', alignItems: 'right' }} >
                                                                            <img
                                                                                src={imagesURL}
                                                                                alt="Event Image"
                                                                                style={{
                                                                                    borderRadius: 8,
                                                                                    cursor: 'pointer',
                                                                                    width: '95%',
                                                                                    maxHeight: '300px',
                                                                                    minHeight: '250px'
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={14} lg={11} xl={11} style={{ textAlign: 'left' }}>
                                                                    <>
                                                                        <div class="five columns">
                                                                            <section class="entry-content">
                                                                                <h1 class="entry-title">
                                                                                    <a href={url_link} rel="bookmark">
                                                                                        <span>{item.title}</span>
                                                                                    </a>
                                                                                </h1>
                                                                                <div class="entry-meta">

                                                                                    <span class="entry-date">{item.convert_date} </span>
                                                                                </div>
                                                                                <div class="entry-excerpt" style={{ display: 'block' }} >
                                                                                    {item.description}
                                                                                </div>

                                                                                <FeatherIcon icon="calendar" size={14} />{' '}{formattedDateRange}
                                                                                <br />
                                                                                <FeatherIcon icon="map-pin" size={14} />{'  '}{item.address}

                                                                            </section>
                                                                        </div>
                                                                    </>
                                                                </Col>
                                                            </Row>
                                                        )


                                                    }
                                                })
                                            }
                                        </Row>
                                    </div>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>

                                    </Row>
                            } */}


                            <Row >
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <div style={{ padding: 24 }}>
                                        <Typography.Title level={2} style={{ textAlign: 'center', marginBottom: 24 }}>
                                            Events organized by fundunity community
                                        </Typography.Title>
                                        <Row justify="center" style={{ marginTop: 24 }}>
                                            <Pagination
                                                current={currentPage}
                                                pageSize={itemsPerPage}
                                                total={searchData.length}
                                                onChange={handlePageChange}
                                            />
                                        </Row>
                                        <Row gutter={[16, 16]} justify="center">
                                            {currentProjects.map((project, index) => (
                                                <Col key={index}>
                                                    <VideoCard {...project} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>

                                    {/************************************/}

                                </Col>

                            </Row>

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export default EventDataList;
