import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import themeUsersReducer from './themeUsers/reducers';
import { readMessageReducer } from './message/reducers';
import { readNotificationReducer } from './notification/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import { headerSearchReducer } from './headerSearch/reducers';
import chartContentReducer from './chartContent/reducers';
import cartData from './cart/reducers';
import cartCountReounducer from './cartCount/reducers';
import percentageReducer from './cartPercentage/reducers';
import organizationsReducer from './organizations/reducers';
import firebaseAuth from './firebase/auth/reducers';


const rootReducers = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  themeUsers: themeUsersReducer,
  headerSearchData: headerSearchReducer,
  message: readMessageReducer,
  notification: readNotificationReducer,
  auth: authReducer,  
  ChangeLayoutMode,
  chartContent: chartContentReducer,
  cart: cartData,
  cartCount:cartCountReounducer,
  percentageData:percentageReducer,
  organizationData:organizationsReducer,
  firebaseAuth,  
});

export default rootReducers;
