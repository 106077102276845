import React, { useState } from 'react';
import { Badge, message } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
import { NotificationOutlined } from '@ant-design/icons';
import CreateProject from '../../../container//project/overview/CreateProject';
//import { AtbdTopDropdwon } from './auth-info-style';
//import { Popover } from '../../popup/popup';
//import Heading from '../../heading/heading';
import OrganizationModal from '../../../../src/container/OrganizationModal';

function ShoppingIcon() {

    const { isAuthenticate, cartData } = useSelector(state => {
        //console.log('----state.cartCount.data',state.cartCount.data);
        return {
            isAuthenticate: state.fb.auth.uid,
            cartData: state.cartCount.data,
        };
    });
    const [state, setState] = useState({
        visible: false,
        isVideoModalVisible: false
    });

    const { rtl } = useSelector(state => {
        return {
            rtl: state.ChangeLayoutMode.rtlData,
        };
    });

    const history = useHistory();

    function renderThumb({ style, ...props }) {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    }

    function renderView({ style, ...props }) {
        const customStyle = {
            marginRight: rtl && 'auto',
            [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
    }

    renderThumb.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    renderView.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    

    const getcart = e => {
        e.preventDefault();
        let donate_item = localStorage.getItem('donate_item') !== null ? JSON.parse(localStorage.getItem('donate_item')) : [];
        if (donate_item.length != 0) {
            if (localStorage.getItem('donate_amount') != null) {
                if (isAuthenticate) {
                    history.push('cart');
                } else {
                    history.push('/checkout');
                }
            } else {
                message.warning("Please add donation amount.", 3);
                showModal();

            }

        } else {
            if (isAuthenticate) {
                history.push('cart');
            } else {
                history.push('/checkout');
            }
        }

    }

    const showModal = () => {
        setState({
            ...state,
            visible: true,
        });
    };

    const onCancel = () => {
        setState({
            ...state,
            visible: false,
        });
    };


    const showVideoModal = () => {
        setState({
            ...state,
            isVideoModalVisible: true,
        });
    };

    const onOrgCancel = () => {
        setState({
            ...state,
            isVideoModalVisible: false,
        });
    };
    const { visible, isVideoModalVisible } = state;

    return (
        <>
            <div className="notification adjust_shopping_icon" >
                {
                    <>
                        <Link to="/news" className="head-example" >
                            <FeatherIcon icon="layout" size={22} />
                        </Link><Link to="/donation-package" className="head-example" >
                            <FeatherIcon icon="layout" size={22} />
                        </Link>
                        <Link to="/events" className="head-example">
                            <FeatherIcon icon="calendar" size={22} />
                        </Link>
                        <Link to="/map-listings" className="head-example">
                        <FeatherIcon icon="map-pin" size={22} />
                    </Link>
                    </>
                }

                                
                <Link to="#" className="head-example" onClick={showVideoModal}>
                    <NotificationOutlined style={{ fontSize: '150%' }} className='notofication-icon' />
                </Link>
                <Badge count={cartData ? cartData : ''} style={{ transform: 'translate(18%, -73%)', paddingTop: '1px', paddingRight: '2px', paddingLeft: '1px', marginTop: '4px', marginLeft: '5px' }}>
                    <Link to="#" className="head-example" onClick={getcart}>
                        <FeatherIcon icon="shopping-cart" size={22} />
                    </Link>
                </Badge>
            </div>

            <CreateProject onCancel={onCancel} visible={visible} />
            <OrganizationModal onCancel={onOrgCancel} visible={isVideoModalVisible} />
        </>

    );
}

export default ShoppingIcon;
