import React, { useState, useEffect } from 'react';
import { Form,  InputNumber,message } from 'antd';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {  useSelector } from 'react-redux';
import { Button } from '../../../components/buttons/buttons';
import { Modal } from '../../../components/modals/antd-modals';
import { BasicFormWrapper } from '../../styled';

function CreateProject({ visible, onCancel }) {
  
  const [form] = Form.useForm();
  const history = useHistory();
  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid,
    };
  });

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
  });

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  const handleOk = values => {
    
    var donate_item =localStorage.getItem('donate_item');
    if(donate_item != null)
    {
      let donate_items = JSON.parse(donate_item);
      let percentage=0;
        donate_items.forEach(element => {
          percentage=percentage+element.percentage
        });
        if(percentage == 100)
        {
          localStorage.setItem('subtotal_amount',values.donation_amount);
            localStorage.setItem('donate_amount',JSON.stringify(values));
            if(isAuthenticate !== undefined)
            {
              history.push('/cart');
            }else{
              history.push('/checkout');
            }
            onCancel();
        }else if (100 < percentage)
        {
          message.error("Total donating percentage is more than 100%.",process.env.REACT_APP_NOTIFICATION_DELAY);
        }else{
          message.error("Please enter donation percentage allocations for the organizations of your choice below.",process.env.REACT_APP_NOTIFICATION_DELAY);
        }
        
    }else{
      message.error("Please set the percentage on the organization card.",process.env.REACT_APP_NOTIFICATION_DELAY)
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };


  return (
    <Modal
      type={state.modalType}
      title="Add Donation Details"
      visible={state.visible}
      footer={[null]}
      onCancel={handleCancel}
      //style={{width:'80%'}}
      className="model-view"
    >
      <div className="project-modal">
        <BasicFormWrapper>
          <Form form={form} name="createProject" onFinish={handleOk} encType="multipart/form-data">
            <Form.Item name="donation_amount" label="" rules={[{ required: true, message: 'Please input your amount'  }]}>
              <InputNumber placeholder="Amount" type="number" name="donation_amount" />
            </Form.Item>            
            
            <div className="project-modal-footer" >
              <Button size="default" htmlType="submit" type="primary" key="submit" style={{marginRight:'8px'}}>
                Checkout
              </Button>
              <Button size="default" type="primary" key="back" outlined onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </BasicFormWrapper>
      </div>
    </Modal>
  );
}

CreateProject.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired  
};

export default CreateProject;
