import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
  };

  const {
    CARTPERCENTAGE_BEGIN,
    CARTPERCENTAGE_SUCCESS,
    CARTPERCENTAGE_ERR
  } = actions;
 
  const cartPercentageReducer = (state = initialState , action) =>{
    const { type, data, err } = action;
    switch (type) {
        case CARTPERCENTAGE_BEGIN:
        return {
            ...initialState,
            loading: true,
        };

        case CARTPERCENTAGE_SUCCESS:
        return {
            ...initialState,
            data,
            error: false,
            loading: false,
        };

        case CARTPERCENTAGE_ERR:
        return {
            ...initialState,
            error: err,
            loading: false,
        };

        default:
      return state;
    }

  }

  export default cartPercentageReducer;