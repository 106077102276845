/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { NavLink, Link } from 'react-router-dom';
//import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
//import MenueItems from './MenueItems';
import TopMenu from './TopMenu';
import { Div, SmallScreenAuthInfo, SmallScreenSearch, TopMenuSearch } from './style';
import HeaderSearch from '../components/header-search/header-search';
import AuthInfo from '../components/utilities/auth-info/info';
import { changeRtlMode, changeLayoutMode, changeMenuMode } from '../redux/themeLayout/actionCreator';
import ShoppingIcon from '../../src/components/utilities/auth-info/ShoppingIcon';
import ContactForm from './ContactForm';

const { darkTheme } = require('../config/theme/themeVariables');

const { Header, Footer, Sider, Content } = Layout;
// const { darkMode } = config;


const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        collapsed: false,
        hide: true,
        searchHide: true,
        activeSearch: false,
        contactModalVisible: false,
      };
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
      this.updateDimensions();
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
      this.setState({
        collapsed: window.innerWidth <= 1200 && true,
      });
    }

    render() {
      const { collapsed, hide, searchHide, activeSearch, contactModalVisible } = this.state;
      //const { ChangeLayoutMode, rtl, topMenu, changeRtl, changeLayout, changeMenuMode } = this.props;
      const { ChangeLayoutMode, rtl, topMenu } = this.props;

      const left = !rtl ? 'left' : 'right';
      const darkMode = ChangeLayoutMode;



      const onShowHide = () => {
        this.setState({
          hide: !hide,
          //searchHide: true,
        });
      };
      const showContactModal = () => {
        this.setState({
          contactModalVisible: true,
        });
      };
      const hideContactModal = () => {
        this.setState({
          contactModalVisible: false,
        });
      };


      /*
      const handleSearchHide = (e) => {
        e.preventDefault();
        this.setState({
          searchHide: !searchHide,
          hide: true,
        });
      };
      */

      const footerStyle = {
        //marginBottom: '0px',
        padding: '10px 5px 10px',
        color: 'rgba(0, 0, 0, 0.65)',
        fontSize: '14px',
        background: 'rgba(255, 255, 255, .90)',
        width: '100%',
        boxShadow: '0 -5px 10px rgba(146,153,184, 0.05)',
        height: '50px !important'
        // width: '-webkit-fill-available',
        // position: 'fixed',
        // left:0,
        // bottom:0,
        // right:0
      };

      const SideBarStyle = {
        margin: '63px 0 0 0',
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        height: '100vh',
        position: 'fixed',
        [left]: 0,
        zIndex: 998,
        display: 'none'
      };



      return (
        <Div darkMode={darkMode}>
          <Layout className="layout">
            <Header
              style={{
                position: 'fixed',
                width: '100%',
                top: 0,
                [!rtl ? 'left' : 'right']: 0,
              }}
            >
              <Row>
                <Col lg={!topMenu ? 4 : 3} sm={4} xs={8} className="align-center-v navbar-brand">
                  {!topMenu || window.innerWidth <= 991 ? (
                    <Button type="link">
                      <Link to="/"><h2 style={{ color: '#606ef2', fontStyle: 'italic' }}><i class="fa fa-archive" aria-hidden="true"></i> Fundunity</h2></Link>
                    </Button>
                  ) : null}
                  <Link
                    className={topMenu && window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'}
                    to="/"
                  >
                  </Link>
                </Col>

                <Col lg={!topMenu ? 14 : 15} md={12} sm={0} xs={0}>
                  <Link to="/" className="header_menu_item" >
                    Home
                  </Link>
                  <Link to="/#" className="header_menu_item" >
                    About Us
                  </Link>
                  <Link to="/#" className="header_menu_item" >
                    Our Portfolio
                  </Link>
                  <Link to="/#" className="header_menu_item" >
                    Contact Us
                  </Link>
                </Col>

                <Col lg={6} md={8} sm={0} xs={0}>
                  {topMenu && window.innerWidth > 991 ? (
                    <TopMenuSearch>
                      <div className="top-right-wrap d-flex">

                        <div className={`${activeSearch ? 'topMenu-search-form show' : 'topMenu-search-form'}`}>
                          <form action="">
                            <span className="search-icon">
                              <FeatherIcon icon="search" />
                            </span>
                            <input type="text" name="search" />
                          </form>
                        </div>
                        <AuthInfo className="mobile-menu-item" />
                      </div>
                    </TopMenuSearch>
                  ) : (
                    <AuthInfo className="mobile-menu-item" />
                  )}
                </Col>

                <Col md={0} sm={18} xs={16}>
                  <div className="mobile-action">
                    <ShoppingIcon />
                    <Link className="btn-auth" onClick={onShowHide} to="#">
                      <FeatherIcon icon="menu" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Header>
            <div className="header-more">
              <Row>
                <Col md={0} sm={24} xs={24}>
                  <div className="small-screen-headerRight">
                    <SmallScreenSearch hide={searchHide} darkMode={darkMode} >
                      <HeaderSearch rtl={rtl} />
                    </SmallScreenSearch>
                    <SmallScreenAuthInfo hide={hide} darkMode={darkMode} >
                      <AuthInfo rtl={rtl} className="mobile-menu-item" />
                    </SmallScreenAuthInfo>
                  </div>
                </Col>
              </Row>
            </div>
            <Layout>
              {!topMenu || window.innerWidth <= 991 ? (
                <ThemeProvider theme={darkTheme}>
                  <Sider width={280} style={SideBarStyle} collapsed={true} theme={!darkMode ? 'light' : 'dark'}></Sider>
                </ThemeProvider>
              ) : null}
              <Layout className="atbd-main-layout menu-footer-adjuest" >
                <Content>
                  <WrappedComponent {...this.props} />
                </Content>
              </Layout>

            </Layout>

            <Footer className="admin-footer" style={footerStyle}>
              <Row>
                <Col sm={12} md={24} lg={12} xs={12}>
                  <span className="admin-footer__copyright">{new Date().getFullYear()} ©FUNDUNITY</span>
                </Col>
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }} sm={12} md={24} lg={12} xs={12}>
                  <div className="admin-footer__links">
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                    <NavLink to="#" onClick={() => { showContactModal() }}>Contact</NavLink>
                    <ContactForm onCancel={() => { hideContactModal() }} visible={contactModalVisible} />
                  </div>
                </Col>
              </Row>
            </Footer>
          </Layout>
        </Div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      ChangeLayoutMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  };

  const mapStateToDispatch = (dispatch) => {
    return {
      changeRtl: (rtl) => dispatch(changeRtlMode(rtl)),
      changeLayout: (show) => dispatch(changeLayoutMode(show)),
      changeMenuMode: (show) => dispatch(changeMenuMode(show)),
    };
  };

  LayoutComponent.propTypes = {
    ChangeLayoutMode: propTypes.bool,
    rtl: propTypes.bool,
    topMenu: propTypes.bool,
    changeRtl: propTypes.func,
    changeLayout: propTypes.func,
    changeMenuMode: propTypes.func,
  };

  return connect(mapStateToProps, mapStateToDispatch)(LayoutComponent);
};
export default ThemeLayout;
