import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Button } from '../../../components/buttons/buttons';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../../styled';
import FeatherIcon from 'feather-icons-react';
import { Table, Form, Modal, notification, Input } from 'antd';
import axios from 'axios';
import DonationPackageList from './DonationPackageList';
import CreateDonationPackage from './CreateDonationPackage';
import EditDonationPackage from './EditDonationPackage';


function DonationPackageListDataTable() {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const [type, setType] = useState(true);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [editvisible, setEditVisible] = useState(null);
  const [donationPackageId, setDonationPackageID] = useState(null);
  const [fundunityDonationPackageData, setfundunityDonationPackageData] = useState([])
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    if (isAuthenticate) {
      getAllFundunityDonationPackage();
    }
  }, []);

  const getAllFundunityDonationPackage = async () => {
    console.log("isAuthenticate",isAuthenticate)
    axios.get(`${process.env.REACT_APP_API_URL}/get-fundunity-donation-package/${isAuthenticate}`)
      .then((response) => {
        //alert(JSON.stringify(response.data));
        setfundunityDonationPackageData(response.data);
        console.log("response.data :", response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  const [form] = Form.useForm();
  const formData = new FormData();
  const excelfileRef = useRef(null);

  const { isFbAuthenticate } = useSelector(state => {
    return {
      isFbAuthenticate: state.fb.auth.uid,
    };
  });


  const create_fund = () => {
    setIsCreateModalVisible(true);
  }

  const onCreateCancel = () => {
    getAllFundunityDonationPackage();
    setIsCreateModalVisible(false);
  };

  const onEditCancel = () => {
    window.location.reload();
    setEditVisible(false);
    setDonationPackageID(null);
  };


  const handleDataFromSubComponent = async (package_id) => {
    setDonationPackageID(package_id);
    if (package_id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get-select-donation-package/${package_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch');
        }

        const data = await response.json();

        if (data && data.length > 0) {
          console.log(data[0])
          setEditData(data[0]);
          setEditVisible(true);
        } else {
          console.error('No data found for ID:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };


  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={"Fundunity Donations Packages"}
          buttons={[
            <Button type="primary" size="default" outlined={type} onClick={create_fund}>
              <FeatherIcon icon="plus" size={16} /> Create New Donation Package
            </Button>
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <DonationPackageList fundunityDonationPackageData={fundunityDonationPackageData} sendDataToParent={handleDataFromSubComponent} />
          </Col>
        </Row>
      </Main>

      {(isCreateModalVisible && <CreateDonationPackage onCancel={onCreateCancel} visible={isCreateModalVisible} />)}

      {(donationPackageId != null && editData != null && <EditDonationPackage onCancel={onEditCancel} visible={editvisible} Id={donationPackageId} editData={editData} />)}


    </>
  );
}

DonationPackageListDataTable.propTypes = {
  match: PropTypes.object,
};
export default DonationPackageListDataTable;
