import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
//import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { Tabs, Button, Form, Input, Select, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import axios from "axios";
import styled from "styled-components";
import { login } from '../../../../redux/authentication/actionCreator';
import { Modal } from '../../../../components/modals/antd-modals';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { getFirebase } from 'react-redux-firebase';
import useInput from './useInput';


const { Option } = Select;

const SignUp = ({ visible, onCancel }) => {

  const [form] = Form.useForm();

  const [logo, setLogo] = useState('');

  const [usermobile, setUserMobile] = useState('');
  const [orgmobile, setOrgMobile] = useState('');

  const { TabPane } = Tabs;

  const { isSignUpError, isSignUpLoading, isFbAuthenticate } = useSelector(state => {
    return {
      isSignUpError: state.firebaseAuth.isSignUpError,
      isSignUpLoading: state.firebaseAuth.isSignUpLoading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();

  const address = useInput("");

  const [state, setState] = useState({
    values: null,
    checked: null
  });

  const [userOTP, setUserOTP] = useState('');
  const [userData, setUserData] = useState('');
  const [visibleUserOTP, setVisibleUserOTP] = useState(false);

  const [orgOTP, setOrgOTP] = useState('');
  const [orgData, setOrgData] = useState('');
  const [visibleOrgOTP, setVisibleOrgOTP] = useState(false);

  const [final, setfinal] = useState('');




  const handleFbLogin = useCallback(() => {
    dispatch(login());
    history.push('/admin');
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
    let unmounted = false;
    if (!unmounted) {

      setState({
        visible
      });
    }
    return () => {
      unmounted = true;
    };

  }, [isFbAuthenticate, handleFbLogin, visible]);

  const handleCancel = () => {
    onCancel();
  };

  const handleUserCountryCode = (value, data, event, formattedValue) => {
    setUserMobile(formattedValue);
  }

  const handleOrgCountryCode = (value, data, event, formattedValue) => {
    setOrgMobile(formattedValue);
  }
  // create an organization account functions
  const handleOrgSubmit = async values => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/verify-phone/${orgmobile}`);
    if (result != "") {
      if (result.data.status === true) {
        setVisibleOrgOTP(false);
        message.error(result.data.message, process.env.REACT_APP_NOTIFICATION_DELAY);
      } else {
        let post_data = { ...values, 
          address: address.addressData.place_name != undefined ? address.addressData.place_name : null,
          address_coordinate: address.addressData.center
         }
        
        setOrgData(post_data);
        const firebase = getFirebase();
        let verify = new firebase.auth.RecaptchaVerifier('org-recaptcha-container');
        firebase.auth().signInWithPhoneNumber(orgmobile, verify).then((response) => {
          setfinal(response);
          message.success("OTP is sent to Your Mobile Number", process.env.REACT_APP_NOTIFICATION_DELAY);
          setVisibleOrgOTP(true);
        })
          .catch((err) => {
            message.error(err, process.env.REACT_APP_NOTIFICATION_DELAY);
            setVisibleOrgOTP(false);
          });
      }
    } else {
      setVisibleOrgOTP(false);
      message.error("Something went to wrong", process.env.REACT_APP_NOTIFICATION_DELAY);
    }

  };
  // create an organization account functions
  const handleUserSubmit = async values => {
    const result = await axios.get(`${process.env.REACT_APP_API_URL}/verify-phone/${usermobile}`);
    if (result != "") {
      if (result.data.status === true) {
        setVisibleUserOTP(false);
        message.error(result.data.message, process.env.REACT_APP_NOTIFICATION_DELAY);
      } else {
        setUserData(values);
        const firebase = getFirebase();
        let verify = new firebase.auth.RecaptchaVerifier('user-recaptcha-container');
        firebase.auth().signInWithPhoneNumber(usermobile, verify).then((response) => {
          setfinal(response);
          message.success("OTP is sent to Your Mobile Number", process.env.REACT_APP_NOTIFICATION_DELAY);
          setVisibleUserOTP(true);
        }).catch((err) => {
          message.error(err, process.env.REACT_APP_NOTIFICATION_DELAY);
          setVisibleUserOTP(false);
        });
      }
    } else {
      setVisibleUserOTP(false);
      message.error("Something went to wrong", process.env.REACT_APP_NOTIFICATION_DELAY);
    }
  };

  //Organizations verify OTP functions
  const ValidateOrganizationOtp = () => {
    if (orgOTP === null || final === null) {
      return;
    } else {
      final.confirm(orgOTP).then((result) => {
        const uid = result.user.uid;
        const signdata = { ...orgData, UID: uid, phone: orgmobile 

        };
        axios.post(`${process.env.REACT_APP_API_URL}/sign-org-up`, signdata)
          .then(function (response) {
            message.success("Signup Successfully", process.env.REACT_APP_NOTIFICATION_DELAY);
            //history.push('admin/cart');
          })
          .catch(function (error) {
            // handle your error
            console.log(error);
          });

      }).catch((err) => {
        message.error("Wrong code", process.env.REACT_APP_NOTIFICATION_DELAY);
      })

    }
  }
  //Users verify OTP functions
  const ValidateUserOtp = () => {
    if (userOTP === null || final === null) {
      return;
    } else {
      final.confirm(userOTP).then((result) => {
        const uid = result.user.uid;
        const signdata = { ...userData, UID: uid, phone: usermobile };
        axios.post(`${process.env.REACT_APP_API_URL}/sign-up`, signdata)
          .then(function (response) {
            message.success("Signup Successfully", process.env.REACT_APP_NOTIFICATION_DELAY);
          })
          .catch(function (error) {
            console.log(error);
          });
      }).catch((err) => {
        message.error("Wrong code", process.env.REACT_APP_NOTIFICATION_DELAY);
      })
    }
  }


  const onChange = checked => {
    setState({ ...state, checked });
  };

  return (
    <React.Fragment>
      <Modal
        type={state.modalType}
        title=""
        visible={state.visible}
        footer={[null]}
        onCancel={handleCancel}
      >
        <AuthWrapper>
          <p className="auth-notice">
            Already have an account? <NavLink to="#" onClick={handleCancel}>Sign In</NavLink>
          </p>
          <Tabs left size='default' style={{ width: '100%' }} >
            <TabPane tab={
              <span>
                <GlobalOutlined />
                Create an Organization Account
              </span>
            } key="1" >
              
              <div className="auth-contents">
                <Form name="register" onFinish={handleOrgSubmit} layout="vertical">
                  <Form.Item label="Organization Name" name="name" rules={[{ required: true, message: 'Please input your Organization name!' }]}>
                    <Input placeholder="Organization Name" />
                  </Form.Item>

                  <Form.Item name="description" label="Organization Description" rules={[{ required: true, message: 'Please input your organization description' }]}>
                    <Input.TextArea rows={4} placeholder="Organization Description" />
                  </Form.Item>

                  <Form.Item name="video_link" label="Organization videos" rules={[{ required: true, message: 'Please input your organization video link' }]}>
                    <Input placeholder="Organization videos" />
                  </Form.Item>
                  <Form.Item name="address" 
                  label="Organization Address" rules={[{ required: true, message: 'Please input your organization address' }]}
                  initialValue={address ? address : ''}
                  >

                    <Input
                      placeholder="Organization Address"
                      {...address}
                      isTyping={address.value !== ""}
                      style={{ marginBottom: 0 }}
                    />
                    {address.suggestions?.length > 0 && (
                      <SuggestionWrapper>
                        {address.suggestions.map((suggestion, index) => (
                          <Suggestion
                            key={index}
                            onClick={() => {
                              address.setValue(suggestion.place_name);
                              address.setSuggestions([]);
                              address.setAddressData(suggestion);
                            }}
                          >
                            {suggestion.place_name}
                          </Suggestion>
                        ))}
                      </SuggestionWrapper>
                    )}
                  </Form.Item>

                  <Form.Item name="contact_name" label="Organization Contact Name" rules={[{ required: true, message: 'Please input your organization contact name' }]}>
                    <Input placeholder="Organization Contact Name" />
                  </Form.Item>

                  <Form.Item name="organization_category" label="Organization Category" rules={[{ required: true, message: 'Please select organization category' }]}>
                    <Select style={{ width: '100%' }}
                      mode="multiple"
                      placeholder="Select Category"
                    >
                      <Option value="Non-profit">Non-profit</Option>
                      <Option value="Ecology">Ecology</Option>
                      <Option value="Climate Change">Climate Change</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item name="email" label="Organization Email" rules={[{ required: true, message: 'Please input your email', type: 'email' }]}>
                    <Input placeholder="Organization Email" type="email" />
                  </Form.Item>

                  <Form.Item name="phone" label="Organization Phone" rules={[{ required: true, message: 'Please input your organization phone' }]}>
                    <PhoneInput
                      country={'us'}
                      inputProps={{
                        name: 'phone'
                      }}
                      inputStyle={{
                        height: '48px',
                        width: '100%'
                      }}
                      enableSearch={true}
                      copyNumbersOnly={false}
                      searchPlaceholder='search'
                      onChange={handleOrgCountryCode}
                    />
                  </Form.Item>
                  {visibleOrgOTP &&
                    <Form.Item name="OTP"
                      initialValue=""
                      label="Enter OTP"
                      rules={[{ required: true, message: 'OTP number required!' }]}
                    >
                      <Input placeholder="Enter your OTP"
                        onChange={(e) => { setOrgOTP(e.target.value) }}
                      />
                    </Form.Item>
                  }

                  {!visibleOrgOTP && <div style={{ paddingTop: '10px', paddingBottom: '10px' }} id="org-recaptcha-container"></div>}

                  {isSignUpError ? <p>{isSignUpError.message}</p> : null}
                  <Form.Item>
                    {!visibleOrgOTP &&
                      <Button className="btn-signin" htmlType="submit" type="danger" size="large" style={{ paddingTop: '10px' }}>
                        Get Code
                      </Button>}
                    {visibleOrgOTP &&
                      <Button className="btn-create" htmlType="submit" type="primary" size="large" onClick={ValidateOrganizationOtp}>
                        {isSignUpLoading ? 'Loading...' : 'Create Account'}
                      </Button>}
                  </Form.Item>
                </Form>
              </div>

            </TabPane>
            <TabPane tab={
              <span>
                <UserOutlined />
                Create a Donor Account
              </span>
            } key="2" >
              <div className="auth-contents">
                <Form name="register" onFinish={handleUserSubmit} layout="vertical">
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                    <Input placeholder=" Name" />
                  </Form.Item>

                  <Form.Item name="address" label="Address">
                    <Input placeholder=" Address" />
                  </Form.Item>

                  <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input your email', type: 'email' }]}>
                    <Input placeholder=" Email" type="email" />
                  </Form.Item>

                  <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone' }]}>
                    <PhoneInput
                      country={'us'}
                      inputProps={{
                        name: 'phone'
                      }}
                      inputStyle={{
                        height: '48px',
                        width: '100%'
                      }}
                      enableSearch={true}
                      copyNumbersOnly={false}
                      searchPlaceholder='search'
                      onChange={handleUserCountryCode}
                    />
                  </Form.Item>
                  {visibleUserOTP &&
                    <Form.Item name="OTP"
                      initialValue=""
                      label="Enter OTP"
                      rules={[{ required: true, message: 'OTP number required!' }]}
                    >
                      <Input placeholder="Enter your OTP"
                        onChange={(e) => { setUserOTP(e.target.value) }}
                      />
                    </Form.Item>
                  }

                  {!visibleUserOTP && <div style={{ paddingTop: '10px', paddingBottom: '10px' }} id="user-recaptcha-container"></div>}
                  {isSignUpError ? <p>{isSignUpError.message}</p> : null}
                  <Form.Item>
                    {!visibleUserOTP &&
                      <Button className="btn-signin" htmlType="submit" type="danger" size="large" style={{ paddingTop: '10px' }}>
                        Get Code
                      </Button>}
                    {visibleUserOTP &&
                      <Button className="btn-create" type="primary" size="large" onClick={ValidateUserOtp}>
                        {isSignUpLoading ? 'Loading...' : 'Create Account'}
                      </Button>}
                  </Form.Item>
                </Form>
              </div>
            </TabPane>
          </Tabs>
        </AuthWrapper>
      </Modal>
    </React.Fragment>
  );
};

export default SignUp;

const SuggestionWrapper = styled.div`
  background: #ecedee;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
  width:100%;
`;

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
  text-align:left;
`;


