import actions from './actions'

const {
    cartCountBegin,
    cartCountSuccess,
    cartCountSendErr
  } = actions;


  const getCartCount = () => {
	return async dispatch => {
		try {
			await dispatch(cartCountBegin());
			//const data = await workService.getCartCount(inputs);
			let donate_items =localStorage.getItem('donate_item');
			//console.log('donate_items',donate_items);
			let count=0;
			if(donate_items!=null)
			{
				let amount_details=JSON.parse(donate_items);
				
				amount_details.forEach(element => {
					count = count + 1;
				});
			}


			let donate_items_package =localStorage.getItem('donate_item_packages');
			//console.log('donate_items',donate_items);
			if(donate_items_package!=null)
			{
				let package_list=JSON.parse(donate_items_package);
				package_list.forEach(element => {
					count = count + 1;
				});
			}
			//console.log('count',count);
			await dispatch(cartCountSuccess(count));
		} catch (err) {
			console.log("err",err)
			dispatch(cartCountSendErr(err));
		}
	};
};


//export default cartPercentage
export { getCartCount};