import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabBasic, Child } from './style';
import { message, Form, Input } from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Button } from '../../components/buttons/buttons';
import axios from "axios";
import { VideoCameraOutlined } from '@ant-design/icons';

const Tab = props => {
  const { data, tabPosition, color } = props;
  let counter = 0;

  const [homecontent, setHomeContent] = useState(null);
  const [mediaURL, setMediaURL] = useState(null);
  const [deletecart, setDeleteCart] = useState(null);
  const [welcomemessage, setWelcomeMessage] = useState(null);
  const [paymentsuccess, setPayment] = useState(null);
  const [emailtemplate, setEmailTemplate] = useState(null);
  const [aboutus, setAboutUs] = useState(null);

  useEffect(() => {
    const homePageData = data.find(item => item.title === 'Home Page');
    if (homePageData) {
      setMediaURL(homePageData.media_link);
    }
  }, [data]);


  const asing_bio = (title, data) => {
    if (title == "Home Page") {
      setHomeContent(data)
    } else if (title == "About Us") {
      setAboutUs(data)
    } else if (title == "Welcome Message") {
      setWelcomeMessage(data)
    } else if (title == "Payment Success Message") {
      setPayment(data)
    } else if (title == "Donation Mail") {
      setEmailTemplate(data)
    } else {
      setDeleteCart(data);
    }
  };


  const handleSaveContent = (id, title, old_content) => {
    var text;
    var url = null;
    if (title == "Home Page") {
      text = homecontent != null ? homecontent : old_content
      url = mediaURL;
    } else if (title == "About Us") {
      text = aboutus != null ? aboutus : old_content
    } else if (title == "Welcome Message") {
      text = welcomemessage != null ? welcomemessage : old_content
    } else if (title == "Payment Success Message") {
      text = paymentsuccess != null ? paymentsuccess : old_content
    } else if (title == "Donation Mail") {
      text = emailtemplate != null ? emailtemplate : old_content
    }
    else {
      text = deletecart != null ? deletecart : old_content
    }


    let content_data = { id: id, content: text, media_link: url };
    axios.post(`${process.env.REACT_APP_API_URL}/update-content`, content_data)
      .then(function (response) {
        if (response.data.status == true) {
          message.success(response.data.message)
        } else {
          message.error(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  return (
    <TabBasic color={color && color} defaultActiveKey="1" tabPosition={tabPosition !== undefined ? tabPosition : 'top'}>
      {data.map(item => {
        const { id, title, content, media_link } = item;
        counter += 1;
        return (
          <Child
            color={color && color}
            tab={
              <span>
                {title}
              </span>
            }
            key={counter}
          >
            <div className="sDash_editor">
              <CKEditor
                editor={ClassicEditor}
                data={content ? content : ''}
                onChange={(event, editor) => { asing_bio(title, editor.getData()) }}
                style={{ borderColor: '#e4e7f0' }} />
            </div>

            {(title == "Home Page" && <>
              <br />
              {/* <Form.Item name="media_link" label="Media URL"
                initialValue={media_link ? media_link : ''}
              >
                <Input placeholder="Media URL" name="media_link" prefix={<VideoCameraOutlined />}
                  onChange={(e) => { setMediaURL(e.target.value) }}
                  defaultValue={media_link}
                />
              </Form.Item> */}
              <Form.Item name="media_link" label="Homepage Background Color"
                initialValue={media_link ? media_link : ''}
              >
                <Input
                  type="color"
                  style={{maxWidth:300}}
                  placeholder="Homepage Background Color"
                  name="background_color"
                  onChange={(e) => { setMediaURL(e.target.value) }}
                  defaultValue={media_link}
                />
              </Form.Item>
            </>)}


            {(title == "Cart Delete Alert" && <>
              <br />
              <b>Note:</b> * Replace with actual percentage.
              <br />
            </>)}

            {(title == "Donation Mail" && <>
              <br />
              <b>Note:</b> <br />1. %name% replace with user name <br />2. %table%  replace with donate amount.
              <br />
            </>)}

            <br />
            <Button type="primary" size="default" onClick={() => handleSaveContent(id, title, content)}>
              Save
            </Button>
          </Child>
        );
      })}
    </TabBasic>
  );
};

Tab.propTypes = {
  color: PropTypes.string,
  tabPosition: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
};

export { Tab };
