import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table, Popover, notification } from 'antd';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../styled';
import { useHistory } from 'react-router-dom';
import Heading from '../../../components/heading/heading';
import { Button } from '../../../components/buttons/buttons';
import { Cards } from '../../../components/cards/frame/cards-frame';
import axios from 'axios';
import actions from '../../../redux/organizations/actions';
import EditEvent from './1EditEvent';

function FeedsTable({ feedData, sendDataToParent }) {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const history = useHistory();
  const usersTableData = [];
  //const [feedData, setfeedData] = useState([]);  
  const [editFeedvisible, setEditFeedVisible] = useState(null);
  const [feedID, setfeedID] = useState(null);


  const showEditModal = (event_id) => {
    //setEditFeedVisible(true);
    sendDataToParent(event_id);
  }

  const showDeleteModal = (event_id, image) => {
    const confirm = window.confirm('Are you sure you want to delete this event?');
    if (confirm) {
      let final_array = { event_id: event_id, images: image }
      axios.post(`${process.env.REACT_APP_API_URL}/delete-event`, final_array)
        .then(function (response) {
          if (response.data.status == true) {
            notification.success({
              message: "Feeds deleted successfully",
            });
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        })
        .catch(function (error) {
          notification.error({
            message: "Someting went wrong"
          });
          //handleImportCancel();
          return false;
        });
    }
  }


  if (0 < feedData.length && feedData != null) {
    feedData.map((val) => {
      const { event_id, title, description, address, image, status, created_at, create_type, tags, location } = val;
      const truncatedTitle = title.length > 20 ? `${title.substring(0, 20)}...` : title;
      const truncatedDescription = description.length > 40 ? `${description.substring(0, 40)}...` : description;
      return usersTableData.push({
        key: event_id,
        title: truncatedTitle,
        image: (<>{image != null && <img src={image} width="80" />}</>),
        link: (<p>{image != null && <a href={image} target='_blank'>Link</a>}</p>),
        description: truncatedDescription,
        created_at: (<p><FeatherIcon icon="calendar" size={14} /> {created_at}</p>),
        create_type: create_type,
        status: (
          <span
            className={`status ${status === 1 ? 'Success' : 'error'
              }`}
          >
            {status === 1 ? 'Active' : 'Inactive'}
          </span>
        ),
        address: address,
        tags: tags,
        action: (
          <>
            <Link to="#" style={{padding:10}}
              onClick={() => { showEditModal(event_id) }}
            >
              <FeatherIcon size={16} icon="edit" />
            </Link>
            <Link to="#" style={{padding:10}}
              onClick={() => { showDeleteModal(event_id, image) }}
            ><FeatherIcon size={16} icon="trash" /></Link>
          </>
        )
      });
    });
  }


  const usersTableColumns = [
    {
      title: 'Title',
      align: 'center',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Image',
      align: 'center',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: 'Description',
      align: 'center',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      align: 'center',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Tags',
      align: 'center',
      dataIndex: 'tags',
      key: 'tags',
    },
    {
      title: 'Address',
      align: 'center',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Create Date',
      align: 'center',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onCompanyCancel = () => {
    setEditFeedVisible(false);
    setfeedID(null);

    //getAllFeeds();
  };


  return (
    <Cards headless>
      <UserTableStyleWrapper>
        <TableWrapper className="table-responsive">
          <Table
            // rowSelection={rowSelection}
            dataSource={usersTableData}
            columns={usersTableColumns}
            pagination={{
              defaultPageSize: 5,
              total: usersTableData.length,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </UserTableStyleWrapper>

    </Cards>

  );
}


export default FeedsTable;
