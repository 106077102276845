import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
//import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { Form, Input, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import Heading from '../../../../components/heading/heading';
import axios from "axios";
// import {
//   fbAuthSignUp,
//   fbAuthLoginWithGoogle,
//   fbAuthLoginWithFacebook,
// } from '../../../../redux/firebase/auth/actionCreator';
import { login } from '../../../../redux/authentication/actionCreator';
import { Modal } from '../../../../components/modals/antd-modals';
import { fbAuthLogin } from '../../../../redux/firebase/auth/actionCreator';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const { Option } = Select;

const UserSignup = ({ visible, onCancel }) => {

  const [form] = Form.useForm();

  const [logo, setLogo] = useState('');
  const [mobile, setMobile] = useState('');

  const { isSignUpError, isSignUpLoading, isFbAuthenticate } = useSelector(state => {
    return {
      isSignUpError: state.firebaseAuth.isSignUpError,
      isSignUpLoading: state.firebaseAuth.isSignUpLoading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    values: null,
    checked: null,
  });

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    history.push('/admin');
  }, [dispatch, history]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
    let unmounted = false;
    if (!unmounted) {

      setState({
        visible
      });
    }
    return () => {
      unmounted = true;
    };

  }, [isFbAuthenticate, handleFbLogin, visible]);

  const handleCancel = () => {
    onCancel();
  };
  const handleCountryCode = (value, data, event, formattedValue) => {
    console.log("formattedValue", formattedValue);
    setMobile(formattedValue);
  }



  const handleSubmit = values => {
    
    const formData = new FormData();
    //dispatch(fbAuthSignUp({ ...values, terms: state.checked }));
    if (logo) {
      formData.append('files', logo)
    }
    var actualObj = { ...values, status: 'inactive', phone: mobile };
    formData.append('data', JSON.stringify(actualObj))

    const promise = new Promise((resolve, reject) => {
      let email_data = { email: values.email };
      axios.post(`${process.env.REACT_APP_API_URL}/check-email`, email_data)
        .then(function (response) {
          if (response.data.status == true) {
            resolve(true);
          } else {
            alert(response.data.message)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    })
    promise.then((d) => {

      axios.post(`${process.env.REACT_APP_API_URL}/create-user`, formData)
        .then(function (response) {
          if (response.status == 200) {
            alert(response.data.message)
            dispatch(fbAuthLogin(actualObj)).then(response => {
              setTimeout(() => {
                history.push('/admin');
                window.location.reload();
              }, 1000);
            })
            form.resetFields();
            //onCancel();
          } else {
            alert("Please try again")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    });
  };

  const onChange = checked => {
    setState({ ...state, checked });
  };

  return (
    <React.Fragment>
      <Modal
        type={state.modalType}
        title=""
        visible={state.visible}
        footer={[null]}
        onCancel={handleCancel}
      >
        <AuthWrapper>
          <p className="auth-notice">
            Already have an account? <NavLink to="#" onClick={handleCancel}>Sign In</NavLink>
          </p>
          <div className="auth-contents">
            <Form name="register" onFinish={handleSubmit} layout="vertical">
              <Heading as="h3">
                Sign Up with your <span className="color-secondary">User</span>
              </Heading>

              <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your name!' }]}>
                <Input placeholder=" Name" />
              </Form.Item>

              <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please input your address' }]}>
                <Input placeholder=" Address" />
              </Form.Item>

              <Form.Item name="phone" label="Phone" rules={[{ required: true, message: 'Please input your phone' }]}>
                {/* <Input placeholder=" Phone" /> */}
                <PhoneInput
                  country={'us'}
                  inputProps={{
                    name: 'phone'
                  }}
                  inputStyle={{
                    height: '48px',
                    width: '100%'
                  }}
                  enableSearch={true}
                  copyNumbersOnly={false}
                  searchPlaceholder='search'
                  //searchNotFound='No entries to show'
                  //countryCodeEditable={false}
                  onChange={handleCountryCode}
                />
              </Form.Item>

              <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input your email', type: 'email' }]}>
                <Input placeholder=" Email" type="email" />
              </Form.Item>

              <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please input your password' },
              { min: 6, message: 'password must be minimum six characters.' }]}>
                <Input.Password placeholder="Password" type="password" />
              </Form.Item>

              <div className="auth-form-action">
                <Checkbox onChange={onChange}>
                  Creating an account means you’re okay with our Terms of Service and Privacy Policy
                </Checkbox>
              </div>
              {isSignUpError ? <p>{isSignUpError.message}</p> : null}
              <Form.Item>
                <Button className="btn-create" htmlType="submit" type="primary" size="large">
                  {isSignUpLoading ? 'Loading...' : 'Create Account'}
                </Button>
              </Form.Item>
              {/* 
          <p className="form-divider">
            <span>Or</span>
          </p>
          <ul className="social-login signin-social">
            <li>
              <Link onClick={() => dispatch(fbAuthLoginWithGoogle)} className="google-signup" to="#">
                <img src={require('../../../../static/img/google.png')} alt="" />
                <span>Sign up with Google</span>
              </Link>
            </li>
            <li>
              <Link onClick={() => dispatch(fbAuthLoginWithFacebook)} className="facebook-sign" to="#">
                <FacebookOutlined />
              </Link>
            </li>
            <li>
              <Link className="twitter-sign" to="#">
                <TwitterOutlined />
              </Link>
            </li>
          </ul> */}
            </Form>
          </div>
        </AuthWrapper>
      </Modal>
    </React.Fragment>
  );
};

export default UserSignup;
