const actions = {
    CART_COUNT_BEGIN: 'CART_COUNT_BEGIN',
    CART_COUNT_SUCCESS: 'CART_COUNT_SUCCESS',
    CART_COUNT_ERR: 'CART_COUNT_ERR',
  
    cartCountBegin: () => {
      return {
        type: actions.CART_COUNT_BEGIN,
      };
    },
  
    cartCountSuccess: data => {
      return {
        type: actions.CART_COUNT_SUCCESS,
        data,
      };
    },
  
    cartCountSendErr: err => {
      return {
        type: actions.CART_COUNT_ERR,
        err,
      };
    }
  };

  
  
  export default actions;