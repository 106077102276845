import React, { useState, useRef, useEffect } from "react";
//import useSwr from "swr";
import ReactMapGL, { Marker, FlyToInterpolator, Popup } from "react-map-gl";
import useSupercluster from "use-supercluster";
import { Modal } from '../../components/modals/antd-modals';
import { Button } from '../../components/buttons/buttons';
import useInput from "../../container/profile/authentication/overview/useInput";
import styled from "styled-components";
import { Form, Input, Select, Col, Row, message, notification, Avatar, Space, DatePicker } from 'antd';
//import greenball from '../../../src/static/img/greenball.svg';
import moment from 'moment';
const { RangePicker } = DatePicker;
// import "./App.css";
const { Option } = Select;

const fetcher = (...args) => fetch(...args).then(response => response.json());

export default function App({ places }) {
    const [form] = Form.useForm();
    const address = useInput("");
    var item_list = ''
    const [filterList, setFilterList] = useState([]);
    const [points, setPoints] = useState([]);
    const [itemList, setItemList] = useState('');
    const [showListIcon, setShowListIcon] = useState(true);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filteredBounds, setFilteredBounds] = useState(null);


    const [viewport, setViewport] = useState({
        latitude: 41.8974,
        longitude: -87.63625,
        width: "100%",
        height: "93vh",
        zoom: 12
    });
    const [selectedPlace, setSelectedPlace] = useState(null);
    const mapRef = useRef();

    useEffect(() => {
        console.log('places on map*********', places);
        if (places.length > 0) {
            places.forEach((place) => {
                item_list += '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><div style="max-height: 400px;min-width: 210px;"><img id="place_image" style="padding: 0px 0px 20px 0px;width: 100%;height: 100%;max-height: 350px;" loading="lazy" src=" ' + place.image_url + '" alt="Image Not Available" /></div><div style="padding: 0px 10px;"><h3 style="padding:0px;font-weight: 600;word-break: break-word;margin-bottom: 5px;" class="post-title col-12"><a target="_blank" href=" ' + place.organization_details_url + '">' + place.name.replace("  ", " ") + '</a></h3><p style="width: 100%;word-break: break-word;margin-bottom: 5px;" class="post-excerpt">' + place.organization_desciption.slice(0, 100) + '</p><p class="post-excerpt"><b>Address: </b>' + place.organization_address.slice(0, 50) + '</p><ul class="post-categories"><li><a target="_blank" href="' + place.organization_details_url + '">Read More</a></li></ul></div></div></div>'
                setItemList(item_list)
            });
        }
        else {
            item_list = '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><h1>No matching item found</h1></div></div>'
            setItemList(item_list)
        }

        let tempPlaces = places.map(place => ({
            type: "Feature",
            properties: {
                cluster: false,
                placeId: place.id,
                image_url: place.image_url,
                name: place.name,
                organization_details_url: place.organization_details_url,
                organization_address: place.organization_address,
                organization_desciption: place.organization_desciption,
                organization_category: place.organization_category
            },
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(place.longitude),
                    parseFloat(place.latitude)
                ]
            }
        }));
        setFilterList(tempPlaces);
        setPoints(tempPlaces);
    }, [places]);


    useEffect(() => {
        // Update filtered bounds when filterList changes
        if (filterList.length > 0) {
            const bounds = [
                [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER],
                [-Number.MAX_SAFE_INTEGER, -Number.MAX_SAFE_INTEGER]
            ];
            filterList.forEach(point => {
                bounds[0][0] = Math.min(bounds[0][0], point.geometry.coordinates[0]);
                bounds[0][1] = Math.min(bounds[0][1], point.geometry.coordinates[1]);
                bounds[1][0] = Math.max(bounds[1][0], point.geometry.coordinates[0]);
                bounds[1][1] = Math.max(bounds[1][1], point.geometry.coordinates[1]);
            });
            setFilteredBounds(bounds);
            setViewport({
                ...viewport,
                // latitude: (bounds[0][1] + bounds[1][1]) / 2,
                // longitude: (bounds[0][0] + bounds[1][0]) / 2,
                latitude: 41.8974,
                longitude: -87.63625,
                zoom: 8, // Adjust zoom level if necessary
                transitionDuration: 1000, // Optional: Smooth transition duration
                transitionInterpolator: new FlyToInterpolator(), // Optional: Interpolation type
                transitionEasing: t => t, // Optional: Transition easing function
            });

        }
    }, [filterList]);

    useEffect(() => {
        // Fit map to filtered bounds
        if (filteredBounds && mapRef.current) {
            mapRef.current.getMap().fitBounds(filteredBounds, { padding: 100 });
        }
    }, [filteredBounds]);

    const bounds = mapRef.current
        ? mapRef.current
            .getMap()
            .getBounds()
            .toArray()
            .flat()
        : null;

    const { clusters, supercluster } = useSupercluster({
        points: filterList,
        bounds,
        zoom: viewport.zoom,
        options: { radius: 75, maxZoom: 24 }
    });

    const handleMarkerClick = (cluster) => {
        setSelectedPlace(cluster);
    };
    const openFilterModal = async (e) => {
        setShowFilterModal(true)
    }
    const resetFilter = async (e) => {
        item_list = '';
        if (places.length > 0) {
            places.forEach((place) => {
                console.log("place : ",place);
                item_list += '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><div style="max-height: 400px;min-width: 210px;"><img id="place_image" style="padding: 0px 0px 20px 0px;width: 100%;height: 100%;max-height: 350px;" loading="lazy" src=" ' + place.image_url + '" alt="Image Not Available" /></div><div style="padding: 0px 10px;"><h3 style="padding:0px;font-weight: 600;word-break: break-word;margin-bottom: 5px;" class="post-title col-12"><a target="_blank" href=" ' + place.organization_details_url + '">' + place.name.replace("  ", " ") + '</a></h3><p style="width: 100%;word-break: break-word;margin-bottom: 5px;" class="post-excerpt">' + place.organization_desciption.slice(0, 100) + '</p><p class="post-excerpt"><b>Address: </b>' + place.organization_address.slice(0, 50) + '</p><ul class="post-categories"><li><a target="_blank" href="' + place.organization_details_url + '">Read More</a></li></ul></div></div></div>'
                setItemList(item_list)
            });
        }
        else {
            item_list = '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><h1>No matching item found</h1></div></div>'
            setItemList(item_list)
        }
        setFilterList(points);
        //address =null;
        form.resetFields();
        address.clear();
    }

    const hideListing = async (e) => {
        setShowListIcon(false)
    }
    const showListings = async (e) => {
        setShowListIcon(true)
    }

    const handleCancelModal = async (e) => {
        setShowFilterModal(false)
    }
    const setFilter = async (values) => {
        var post_data = { ...values, organization_address: address.addressData.place_name, address_coordinate: address.addressData.center };
        console.log("post_data ***********", post_data)
        console.log("points ***********", points)
        var tempfilterListForDistanceFilter = []
        //filter for distance
        points.forEach(element => {
            if (calculateDistance(element.geometry.coordinates[1], element.geometry.coordinates[0], post_data.address_coordinate[1], post_data.address_coordinate[0]) <= post_data.radius) {
                tempfilterListForDistanceFilter.push(element)
            }
        });
        //filter for type
        var tempfilterListPropertyTypeFilter = []
        tempfilterListForDistanceFilter.forEach(tempPlace => {
            let organization_category = JSON.parse(tempPlace.properties.organization_category);
            if (organization_category.length > 0 && organization_category.includes(post_data.organization_category)) {
                tempfilterListPropertyTypeFilter.push(tempPlace)
            }
        });
        console.log("tempfilterList ***********", tempfilterListPropertyTypeFilter)

        setFilterList(tempfilterListPropertyTypeFilter)
        item_list = ''
        if (tempfilterListPropertyTypeFilter.length > 0) {
            tempfilterListPropertyTypeFilter.forEach((place) => {
                item_list += '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><div style="max-height: 400px;min-width: 210px;"><img id="place_image" style="padding: 0px 0px 20px 0px;width: 100%;height: 100%;max-height: 350px;" loading="lazy" src=" ' + place.properties.image_url + '" alt="Image Not Available" /></div><div style="padding: 0px 10px;"><h3 style="padding:0px;font-weight: 600;word-break: break-word;margin-bottom: 5px;" class="post-title col-12"><a target="_blank" href=" ' + place.properties.organization_details_url + '">' + place.properties.name.replace("  ", " ") + '</a></h3><p style="width: 100%;word-break: break-word;margin-bottom: 5px;" class="post-excerpt">' + place.properties.organization_desciption.slice(0, 100) + '</p><p class="post-excerpt"><b>Address: </b>' + place.properties.organization_address.slice(0, 50) + '</p><ul class="post-categories"><li><a target="_blank" href="' + place.properties.organization_details_url + '">Read More</a></li></ul></div></div></div>'
            });
            setItemList(item_list)
        }
        else {
            item_list = '<div style="background-color: white;margin: 5px 0px;padding: 0px;  box-shadow: 0px 8px 6px -6px #000000;" class="col-lg-12 col-md-12"><div class="blog-post texas"><h1>No matching item found</h1></div></div>'
            setItemList(item_list)
        }
        
    }

    // Utility function to calculate distance between two sets of coordinates
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 3958.8; // Radius of the Earth in miles
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((lat1 * Math.PI) / 180) *
            Math.cos((lat2 * Math.PI) / 180) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in miles
        return distance;
    };

    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    const disabledDate = current => {
        // Disable dates before today
        return current && current < moment().startOf('day');
    };
    const handleDateChange = (dates, dateStrings) => {
        console.log('Selected Dates:', dateStrings);

    };

    return (
        <div>
            <div>
                <div className="filter-icons-box" id="box">
                    <i onClick={openFilterModal} title="Filter results" style={{ cursor: 'pointer', margin: '0px 30px', color: 'white', fontSize: '25px' }} id="filter_icon"
                        className="fa fa-filter" aria-hidden="true"></i>
                    <i onClick={resetFilter} title="Clear filter results" style={{ cursor: 'pointer', margin: '0px 30px', color: 'white', fontSize: '25px' }} id="clear_filter_icon"
                        className="fa fa-undo" aria-hidden="true"></i>
                    <i onClick={hideListing} id="hide_result_list" title="Hide list" className='fa fa-eye-slash'
                        style={{ display: showListIcon ? 'inline-block' : 'none', color: 'white', fontSize: '25px', cursor: 'pointer', margin: '0px 30px' }}></i>
                    <i onClick={showListings} id="show_result_list" title="Show list" className='fa fa-eye'
                        style={{ display: showListIcon ? 'none' : 'inline-block', color: 'white', fontSize: '25px', cursor: 'pointer', margin: '0px 30px' }}></i>
                </div>

                <div style={{ marginTop: 45, width: 400, overflowY: 'auto', maxHeight: 700, position: 'absolute', zIndex: 1, display: !showListIcon ? 'none' : 'inline-block' }} dangerouslySetInnerHTML={{ __html: itemList }} />
            </div>
            <ReactMapGL
                {...viewport}
                maxZoom={20}
                mapStyle='mapbox://styles/mapbox/streets-v11'
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                onViewportChange={newViewport => {
                    setViewport({ ...newViewport });
                }}
                ref={mapRef}
            >
                {
                    filterList && filterList.length < 0 ?
                        <h1>Loading</h1>
                        :
                        <>
                            {clusters.map(cluster => {
                                const [longitude, latitude] = cluster.geometry.coordinates;
                                const {
                                    cluster: isCluster,
                                    point_count: pointCount
                                } = cluster.properties;

                                if (isCluster) {
                                    return (
                                        <Marker
                                            key={`cluster-${cluster.id}`}
                                            latitude={latitude}
                                            longitude={longitude}
                                        >
                                            <div
                                                className="cluster-marker"
                                                style={{
                                                    width: `${10 + (pointCount / filterList.length) * 20}px`,
                                                    height: `${10 + (pointCount / filterList.length) * 20}px`
                                                }}
                                                onClick={() => {
                                                    const expansionZoom = Math.min(
                                                        supercluster.getClusterExpansionZoom(cluster.id),
                                                        20
                                                    );

                                                    setViewport({
                                                        ...viewport,
                                                        latitude,
                                                        longitude,
                                                        zoom: expansionZoom,
                                                        transitionInterpolator: new FlyToInterpolator({
                                                            speed: 2
                                                        }),
                                                        transitionDuration: "auto"
                                                    });
                                                }}
                                            >
                                                {pointCount}
                                            </div>
                                        </Marker>
                                    );
                                }

                                return (
                                    <Marker
                                        key={`crime-${cluster.properties.placeId}`}
                                        latitude={latitude}
                                        longitude={longitude}
                                        onClick={() => handleMarkerClick(cluster)}
                                    >
                                        <i class="fa fa-map-marker" style={{ fontSize: 25, color: 'red' }} aria-hidden="true"></i>
                                    </Marker>
                                );
                            })}
                            {selectedPlace && (
                                <Popup
                                    latitude={selectedPlace.geometry.coordinates[1]}
                                    longitude={selectedPlace.geometry.coordinates[0]}
                                    onClose={() => setSelectedPlace(null)}
                                >
                                    <div style={{ maxHeight: '500px', minWidth: '210px' }}>
                                        <img id="marker_image" style={{ padding: '15px 0px 20px 0px', width: '100%', height: '100%', maxHeight: '350px', maxWidth: 400 }} loading="lazy" src={selectedPlace.properties.image_url} />
                                    </div>
                                    <div style={{ padding: '5px 20px 25px 20px' }}>
                                        <h2 style={{ fontWeight: 600, wordBreak: 'break-word' }} id="marker_title">{selectedPlace.properties.name}</h2>
                                        <p style={{ wordBreak: 'break-word' }} id="marker_description">{selectedPlace.properties.property_desciption}</p>
                                        <p><b>Address: </b><span style={{ wordBreak: 'break-word' }} id="marker_tags">{selectedPlace.properties.organization_address}</span></p>
                                        <a href={selectedPlace.properties.organization_details_url} target="_blank" id="read_more_link" style={{ color: '#ff214f' }}>READ MORE</a>
                                    </div>
                                </Popup>
                            )}
                        </>
                }

            </ReactMapGL>
            <Modal
                type="primary"
                title="Set Filters"
                visible={showFilterModal}
                onCancel={handleCancelModal}
                width={860}
                backdrop="static"
                footer={null}
            >
                <div className="project-modal">
                    <Form form={form} name="createProject" onFinish={setFilter}>

                        <Form.Item
                            {...formItemLayout}
                            name="organization_category" initialValue="" label="Category"
                            rules={[{ required: true, message: 'Please select organization category' }]}
                        >
                            <Select style={{ width: '100%' }}>
                                <Option value="">Organization category</Option>
                                <Option value="Non-profit">Non-profit</Option>
                                <Option value="Ecology">Ecology</Option>
                                <Option value="Climate Change">Climate Change</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            name="organization_address"
                            label="Address"
                            rules={[
                                { required: true, message: 'Please input center of address or select from suggestions' },
                            ]}
                            initialValue={address ? address : ''}
                        >
                            <Input
                                placeholder="Address"
                                {...address}
                                isTyping={address.value !== ""}
                                style={{ marginBottom: 0 }}
                            />
                            {address.suggestions?.length > 0 && (
                                <SuggestionWrapper>
                                    {address.suggestions.map((suggestion, index) => (
                                        <Suggestion
                                            key={index}
                                            onClick={() => {
                                                address.setValue(suggestion.place_name);
                                                address.setSuggestions([]);
                                                address.setAddressData(suggestion);
                                            }}
                                        >
                                            {suggestion.place_name}
                                        </Suggestion>
                                    ))}
                                </SuggestionWrapper>
                            )}
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            name="radius" label="Radius"
                            rules={[{ required: true, message: 'Please input readius of area' }]}
                        >
                            <Input placeholder="Radius in miles" />
                        </Form.Item>

                        <div>
                            <Button style={{ marginRight: 10 }} size="default" htmlType="submit" type="primary" disabled={buttonDisable}>
                                Set Filter {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                            </Button>
                            <Button size="default" type="white" outlined onClick={handleCancelModal}>
                                Cancel
                            </Button>
                        </div>

                    </Form>
                </div>
            </Modal>
        </div>
    );
}

const SuggestionWrapper = styled.div`
  background: #ecedee;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
  width:100%;
`;

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
  text-align:left;
`;
