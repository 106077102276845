import React, { useState,useEffect } from 'react';
import { Badge } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link,useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
//import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector } from 'react-redux';
//import { AtbdTopDropdwon } from './auth-info-style';
//import { Popover } from '../../popup/popup';
//import Heading from '../../heading/heading';

function HomeIcon() {

    const { rtl } = useSelector(state => {
        return {
            rtl: state.ChangeLayoutMode.rtlData,
        };
    });
    
    const history = useHistory();

    function renderThumb({ style, ...props }) {
        const thumbStyle = {
            borderRadius: 6,
            backgroundColor: '#F1F2F6',
        };
        return <div style={{ ...style, ...thumbStyle }} {...props} />;
    }

    const renderTrackVertical = () => {
        const thumbStyle = {
            position: 'absolute',
            width: '6px',
            transition: 'opacity 200ms ease 0s',
            opacity: 0,
            [rtl ? 'left' : 'right']: '2px',
            bottom: '2px',
            top: '2px',
            borderRadius: '3px',
        };
        return <div className="hello" style={thumbStyle} />;
    };

    function renderView({ style, ...props }) {
        const customStyle = {
            marginRight: rtl && 'auto',
            [rtl ? 'marginLeft' : 'marginRight']: '-17px',
        };
        return <div {...props} style={{ ...style, ...customStyle }} />;
    }

    renderThumb.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    renderView.propTypes = {
        style: PropTypes.shape(PropTypes.object),
    };

    // const getcart = e => {
    //     e.preventDefault();
    //     //history.push('');
    // }


    return (
        <div className="notification" >
                <Link to="/organizations-list" className="head-example">
                    <FeatherIcon icon="tag" size={20} />
                </Link>
        </div>
    );
}

export default HomeIcon;
