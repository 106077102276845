import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';
import useInput from '../../profile/authentication/overview/useInput';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';


const { TextArea } = Input;

const EditEvent = ({ visible, Id, onCancel, editData }) => {
    console.log("editData : ",editData);

    const dispatch = useDispatch();

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid
        };
    });

    //let address = useInput();
    let address = useInput(editData != null ? editData.address : '');


    const [form] = Form.useForm();
    const formData = new FormData();
    //const [is_framed, setIsFramed] = useState(false);
    //const [editData, setEditData] = useState(null);
    const [ShowForm, setShowForm] = useState(false);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        checked: [],
        title: '',
        description: '',
        link: '',
        image: '',
        status: '',
        isLoading: false
    });

    const handleDropdownChange = (value, name) => {
        // return;
        setState({
            ...state,
            [name]: value,
        });
    }


    useEffect(() => {
        sessionStorage.removeItem('tab-index');
        if (editData) {
            getElementData(editData);
        }
        return () => { };
    }, [Id]);

    const getElementData = async (editData) => {
        try {
            setStartTime(editData.start_time);
            setEndTime(editData.end_time);
            setShowForm(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
      }
    


    const [image, setImage] = useState('');

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);




    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [start_time, setStartTime] = useState(null);
    const [end_time, setEndTime] = useState(null);


    const fileobj = [];


    const handleOk = (values) => {

        //console.log("values : ", values);

        if (address.addressData.place_name == undefined && values.location == null && values.location == '') {
            message.warning("Please input your event location", process.env.REACT_APP_NOTIFICATION_DELAY);
            return false
        }

        const date_active = moment(values.date_active).format('YYYY-MM-DD');
        const date_expiry = moment(values.date_expiry).format('YYYY-MM-DD');
        const start_time1 = start_time != "" && moment(start_time).format(format) != "Invalid date" ? moment(start_time).format(format) : editData.start_time;
        const end_time1 = end_time != "" && moment(end_time).format(format) != "Invalid date" ? moment(end_time).format(format) : editData.end_time;

        let post_data = {
            uid: isAuthenticate,
            event_id: editData.event_id,
            ...values,
            old_image: editData.image,
            date_active: date_active,
            date_expiry: date_expiry,
            start_time: start_time1,
            end_time: end_time1,
            status: editData.status,
            address: address.addressData.place_name != undefined ? address.addressData.place_name : values.location,
            address_coordinate: address.addressData.center != undefined ? address.addressData.center : (editData.address_coordinate != null ? JSON.parse(editData.address_coordinate) : null),
        };

        if (isAuthenticate == process.env.REACT_APP_ADMIN_UID) {
            post_data = {
                uid: isAuthenticate,
                event_id: editData.event_id,
                ...values,
                old_image: editData.image,
                date_active: date_active,
                date_expiry: date_expiry,
                start_time: start_time1,
                end_time: end_time1,
                status: values.status,
                address: address.addressData.place_name != undefined ? address.addressData.place_name : values.location,
                address_coordinate: address.addressData.center != undefined ? address.addressData.center : (editData.address_coordinate != null ? JSON.parse(editData.address_coordinate) : null),
            };
        }
        //console.log(post_data); return;

        //console.log(values);
        //console.log(start_time1, end_time1);
        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });


        console.log(post_data);
        //return false;

        const formData = new FormData();
        if (image) {
            formData.append('files', image);
        }

        formData.append('data', JSON.stringify(post_data))

        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/update-event`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The event update successfully.");
                    window.location.reload();
                    //onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to update the event");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };




    const handleCancel = () => {
        onCancel();
    };

    const handleImageChange = (e) => {
        // console.log('this is e')
        console.log("e.target.files", e.target.files);
        //console.log(e.target.name)
        if (e.target.files.length) {
            for (var i = 0; i < e.target.files.length; i++) {
                if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
                    message.error('Invalid image format', process.env.REACT_APP_NOTIFICATION_DELAY)
                    return false;
                }
            }
            setImage(e.target.files[0]);
        } else {
            setImage("");
        }
    }




    return (

        <>

            <Modal
                type={state.modalType}
                title="Edit Event"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            {ShowForm == true &&
                                <>
                                    <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">

                                        <Form.Item name="title" label="Title"
                                            rules={[{ required: true, message: 'Please input event title' }]}
                                            initialValue={editData ? editData.title : ''}
                                        >
                                            <Input.TextArea rows={2} placeholder="" name="title" />
                                        </Form.Item>

                                        <Form.Item name="description" label="Description"
                                            rules={[{ required: true, message: 'Please input event description' }]}
                                            initialValue={editData ? editData.description : ''}
                                        >
                                            <TextArea rows={3} type='text' name='description' />
                                        </Form.Item>

                                        <Form.Item name="image" label="Image URL"
                                        >
                                            <Input type='file' name='image' onChange={handleImageChange} />
                                        </Form.Item>

                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="date_active" label="Start Date"
                                                    rules={[{ required: true, message: 'Please input start date' }]}
                                                    //initialValue={editData ? moment(editData.date_active, dateFormat) : ''}
                                                    initialValue={editData && editData.date_active ? moment(editData.date_active) : null}
                                                >
                                                    <DatePicker format={dateFormat} />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >

                                                <Form.Item name="start_time" label="Start Time"
                                                    rules={[{ required: true, message: 'Please input start time' }]}
                                                    initialValue={start_time != '' && moment(start_time, format)}
                                                >
                                                    <TimePicker
                                                        value={start_time != '' && moment(start_time, format)}
                                                        defaultValue={start_time != '' && moment(start_time, format)}
                                                        use12Hours
                                                        placeholder=" Select time"
                                                        format={format}
                                                        onChange={(time, timeString) => { setStartTime(time != null ? time : "") }}
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="date_expiry" label="End Date"
                                                    rules={[{ required: true, message: 'Please input end date' }]}
                                                    //initialValue={editData ? moment(editData.date_expiry, dateFormat) : ''}
                                                    initialValue={editData && editData.date_expiry ? moment(editData.date_expiry) : null}
                                                >
                                                    <DatePicker format={dateFormat} />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="end_time" label="End Time"
                                                    rules={[{ required: true, message: 'Please input end time' }]}
                                                    initialValue={end_time != '' && moment(end_time, format)}
                                                >
                                                    <TimePicker
                                                        value={end_time != '' && moment(end_time, format)}
                                                        defaultValue={end_time != '' && moment(end_time, format)}
                                                        use12Hours
                                                        placeholder=" Select time"
                                                        format={format}
                                                        onChange={(time, timeString) => { setEndTime(time != null ? time : "") }}
                                                        allowClear
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item name="tags" label="Tags"
                                            rules={[{ required: true, message: 'Please input tags' }]}
                                            initialValue={editData ? editData.tags : ''}
                                        >
                                            <Input placeholder="tags" name="tags" />
                                        </Form.Item>

                                        <Form.Item name="location" label="Location"
                                            initialValue={editData ? editData.address : ''}
                                        >
                                            <Input
                                                placeholder="Event Address"
                                                {...address}
                                                isTyping={address.value !== ""}
                                                style={{ marginBottom: 0, borderColor: '#e4e7f0' }}
                                            />
                                            {address.suggestions?.length > 0 && (
                                                <SuggestionWrapper>
                                                    {address.suggestions.map((suggestion, index) => {
                                                        return (
                                                            <Suggestion
                                                                key={index}
                                                                onClick={() => {
                                                                    address.setValue(suggestion.place_name);
                                                                    address.setSuggestions([]);
                                                                    address.setAddressData(suggestion)
                                                                }}
                                                            >
                                                                {suggestion.place_name}
                                                            </Suggestion>
                                                        );
                                                    })}
                                                </SuggestionWrapper>
                                            )}
                                        </Form.Item>

                                        {isAuthenticate === process.env.REACT_APP_ADMIN_UID ?
                                            <>
                                                <Form.Item name="status" label="Status"
                                                    rules={[{ required: true, message: 'Please input status' }]}
                                                    initialValue={Number(JSON.parse(editData.status))}
                                                >
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                                                        onChange={(value) => handleDropdownChange(value, 'status')}
                                                    >
                                                        <Option key={1} value={1}>Active</Option>
                                                        <Option key={0} value={0}>Inactive</Option>
                                                    </Select>
                                                </Form.Item>
                                            </>
                                            : ""}


                                        <Form.Item>

                                            <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                                Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                            </Button>
                                            {' '}
                                            <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>}
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

EditEvent.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default EditEvent;


const SuggestionWrapper = styled.div`
  background: #ecedee;
  width: 400px;
  padding: 10px 20px;
  border-radius: 0px 0px 10px 10px;
  width:100%;
`;

const Suggestion = styled.p`
  cursor: pointer;
  max-width: 400px;
  text-align:left;
`;

