import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Button } from '../../../components/buttons/buttons';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../../styled';
import FeatherIcon from 'feather-icons-react';
import { Table, Form, Modal, notification, Input } from 'antd';
import axios from 'axios';
import XLSX from 'xlsx';
import EventsTable from './EventsTable';
import CreateEvent from './CreateEvent';
import EditEvent from './EditEvent';
import moment from 'moment';


function EventsListDataTable() {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const [type, setType] = useState(true);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [editFeedvisible, setEditFeedVisible] = useState(null);
  const [eventID, setEventID] = useState(null);
  const [NumberOfEventsCreated, setNumberOfEventsCreated] = useState(null);
  const [NumberOfMaxEventsAllowed, setNumberOfMaxEventsAllowed] = useState(null);
  const [feedData, setfeedData] = useState([])
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    if (isAuthenticate) {
      getAllFeeds();
      getOrgPlanDetails();
    }
  }, []);

  const getAllFeeds = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/get-events/${isAuthenticate}/0`)
      .then((response) => {
        //alert(JSON.stringify(response.data));
        setfeedData(response.data);
        console.log("response.data :", response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  const getOrgPlanDetails = async () => {
    var post_data = { uid: isAuthenticate };
    axios.post(`${process.env.REACT_APP_API_URL}/get-organisation-plan-details`, post_data)
      .then((response) => {
        setNumberOfEventsCreated(response.data.NumberOfEventsCreated)
        setNumberOfMaxEventsAllowed(response.data.NumberOfMaxEventsAllowed)
      });
  }

  const [form] = Form.useForm();
  const formData = new FormData();
  const excelfileRef = useRef(null);

  const { isFbAuthenticate } = useSelector(state => {
    return {
      isFbAuthenticate: state.fb.auth.uid,
    };
  });


  const create_feed_excel = () => {
    if (NumberOfEventsCreated < NumberOfMaxEventsAllowed) {
      setIsCreateModalVisible(true);
    } else {
      setWarningModal(true);
    }
  }
  const handleWarningModalCancel = () => {
    setWarningModal(false);
  }
  

  const onCreateCancel = () => {
    getAllFeeds();
    setIsCreateModalVisible(false);
  };

  const onCompanyCancel = () => {
    window.location.reload();
    setEditFeedVisible(false);
    setEventID(null);
    //getAllFeeds();
  };


  const handleDataFromSubComponent = async (event_ID) => {
    setEventID(event_ID);

    if (event_ID) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get-select-event/${event_ID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch');
        }

        const data = await response.json();

        if (data && data.length > 0) {
          setEditData(data[0]);
          setEditFeedVisible(true);
        } else {
          console.error('No data found for ID:', data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };


  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={"Events History "}
          buttons={[
            <Button type="primary" size="default" outlined={type} onClick={create_feed_excel}>
              <FeatherIcon icon="plus" size={16} /> Create Event
            </Button>
          ]}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <EventsTable feedData={feedData} sendDataToParent={handleDataFromSubComponent} />
          </Col>
        </Row>
      </Main>

      {(isCreateModalVisible && <CreateEvent onCancel={onCreateCancel} visible={isCreateModalVisible} />)}

      {(eventID != null && editData != null && <EditEvent onCancel={onCompanyCancel} visible={editFeedvisible} Id={eventID} editData={editData} />)}

      <Modal
        type="primary"
        title="Warning Limit"
        open={warningModal}
        footer={null}
        onCancel={handleWarningModalCancel}
      >
        <div className="project-modal">
         <h3>As per your plan you cannot create more events.You have reached your limits</h3>
        </div>
      </Modal>
    </>
  );
}

EventsListDataTable.propTypes = {
  match: PropTypes.object,
};
export default EventsListDataTable;
