import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, InputNumber, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import { headerSearchAction } from '../../../redux/headerSearch/actionCreator';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';



const { TextArea } = Input;

const CreateFundDonationPackage = ({ visible, onCancel }) => {

    const dispatch = useDispatch();
    const isFbAuthenticate = useSelector(state => {
        return {
            isFbAuthenticate: state.fb.auth.uid,
        }
    });

    const [form] = Form.useForm();
    const formData = new FormData();
    const searchData = useSelector(state => state.headerSearchData);
    //const [is_framed, setIsFramed] = useState(false);
    useEffect(() => {
        if (headerSearchAction) {
            dispatch(headerSearchAction(1));
        }
    }, [dispatch]);
    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        checked: [],
        title: '',
        description: '',
        link: '',
        image: '',
        status: '',
        isLoading: false
    });


    const [image, setImage] = useState('');

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);
    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);

    const [UID, setOrgUID] = useState(null);

    const onSelect = (selectedItems) => {
        console.log("selectedItems :", selectedItems);
        setOrgUID(selectedItems);
    }
    const handleDropdownChange = (value, name) => {
        // return;
        setState({
            ...state,
            [name]: value,
        });
    }

    const handleOk = (values) => {
        let post_data = {
            uid: isFbAuthenticate.isFbAuthenticate,
            ...values,
        };

        console.log(values);

        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });


        console.log(post_data);

        const formData = new FormData();
        formData.append('data', JSON.stringify(post_data))
        setButton(true);
        setLoading(true);
        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/create-fundunity-donation-package`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The Fundunity donation package created successfully.");
                    //window.location.reload();
                    onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to create the fundunity donation package");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };
    const handleCancel = () => {
        onCancel();
    };
    return (

        <>

            <Modal
                type={state.modalType}
                title="Create Fundunity Donation Package"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">
                                <Form.Item name="name" label="Name"
                                    rules={[{ required: true, message: 'Please input plan name' }]}

                                >
                                    <Input placeholder="Plan Name" name="name" />
                                </Form.Item>

                                <Form.Item name="description" label="Description"
                                    rules={[{ required: true, message: 'Please input description regarding plan' }]}
                                >
                                    <TextArea rows={3} type='text' name='description' />
                                </Form.Item>
                                <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input amount' }]}>
                                    <InputNumber style={{ width: 300 }} placeholder="Amount" type="number" name="amount" />
                                </Form.Item>
                                <Form.Item name="organisations" label="Organisations" rules={[{ required: true, message: 'Please select organizations' }]}
                                >
                                    <Select style={{ width: '100%' }}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="large" mode="multiple" className="sDash_fullwidth-select"
                                    >
                                        {
                                            searchData.length ? (
                                                searchData.map(value => {

                                                    return (<Select.Option value={value.uid}>{value.title}</Select.Option>)
                                                })) : ""}
                                    </Select>
                                </Form.Item>

                                {/* <Form.Item name="organisations" label=""
                                    //{...formItemLayout}
                                    style={{ marginRight: '5px' }}
                                >
                                    <Select onChange={onSelect} placeholder="Select Organization" className='mutiple-select'>
                                        <Select.Option value={null}>All Organization</Select.Option>
                                        {
                                            searchData.length ? (
                                                searchData.map(value => {

                                                    return (<Select.Option value={value.uid}>{value.title}</Select.Option>)
                                                })) : ""}
                                    </Select>
                                </Form.Item> */}
                                <Form.Item name="status" label="Status"
                                    rules={[{ required: true, message: 'Please input status' }]}
                                >
                                    <Select
                                        getPopupContainer={trigger => trigger.parentNode}
                                        size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                                        onChange={(value) => handleDropdownChange(value, 'status')}
                                    >
                                        <Option key={1} value={1}>Active</Option>
                                        <Option key={0} value={0}>Inactive</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item>

                                    <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                        Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                    </Button>
                                    {' '}
                                    <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                        Cancel
                                    </Button>
                                </Form.Item>
                            </Form>
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

CreateFundDonationPackage.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default CreateFundDonationPackage;