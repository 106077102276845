import { Input, Col, Row, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import ImageCombiner from './ImageCombiner';
import { getCartCount } from './../../../redux/cartCount/actionCreator';

const DonationPackageList = () => {
    const [donationPackageData, setDonationPackageData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [warningModal, setWarningModal] = useState(false);
    const dispatch = useDispatch();


    const [packageName, setPackageName] = useState("");
    const [packageDesc, setPackageDesc] = useState("");
    const [packageAmount, setPackageAmount] = useState("");
    const [packageLogos, setPackageLogos] = useState("");

    useEffect(() => {
        // Load cart items from local storage when the component mounts
        const savedCartItems = localStorage.getItem('donate_item_packages')
            ? JSON.parse(localStorage.getItem('donate_item_packages'))
            : [];
        setCartItems(savedCartItems);

        axios.get(`${process.env.REACT_APP_API_URL}/get-active-fundunity-donation-package`)
            .then(function (response) {
                if (response.data.length > 0) {
                    const initialData = response.data.map(item => {
                        // Find if the item is already in the cart
                        const savedItem = savedCartItems.find(cartItem => cartItem.id === item.package_id);
                        return {
                            ...item,
                            count: savedItem ? savedItem.count : 1 // Initialize count for each item
                        };
                    });

                    setDonationPackageData(initialData);
                    setSearchData(initialData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);
    console.log("searchData *****",searchData)

    const addToCart = (id, name, amount, organisations, count) => {
        let updatedCart = [...cartItems];
        const existingItemIndex = updatedCart.findIndex(el => el.id === id);

        if (existingItemIndex > -1) {
            updatedCart[existingItemIndex].count = count;
        } else {
            let organisations_list = JSON.stringify(JSON.parse(organisations)).replace(/\"/g, "'");
            const newItem = { id, name, amount, organisations_list, count };
            updatedCart.push(newItem);
        }

        setCartItems(updatedCart);
        localStorage.setItem('donate_item_packages', JSON.stringify(updatedCart));
        if (getCartCount) {
            dispatch(getCartCount());
        }
    };

    const removeFromCart = (id) => {
        const updatedCart = cartItems.filter(item => item.id !== id);
        setCartItems(updatedCart);
        localStorage.setItem('donate_item_packages', JSON.stringify(updatedCart));

        // Reset count to 1 for the item in the data
        const updatedData = searchData.map(item =>
            item.package_id === id ? { ...item, count: 1 } : item
        );
        setSearchData(updatedData);
        setDonationPackageData(updatedData);
        if (getCartCount) {
            dispatch(getCartCount());
        }
    };

    const searchRecords = (searchText) => {
        const filteredData = donationPackageData.filter(item =>
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase())
        );
        setSearchData(filteredData);
    };

    const updateCount = (id, newCount) => {
        const updatedData = searchData.map(item =>
            item.package_id === id ? { ...item, count: newCount } : item
        );
        setSearchData(updatedData);
        setDonationPackageData(updatedData);
    };

    const showDetailsModal = (id, name, description,  amount,logos) => {
        setPackageName(name);
        setPackageDesc(description)
        setPackageAmount(amount)
        setPackageLogos(logos)
        setWarningModal(true)
    };
    const handleModalCancel = () => {
        setWarningModal(false) 
        setPackageName("");
        setPackageDesc("")
        setPackageAmount("")
        setPackageLogos("")
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ marginTop: 10, paddingBottom: 60 }}>
                <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                    <Col xs={24} sm={24} md={20} lg={20}>
                        <Row justify="center" style={{ margin: '10px 0' }}>
                            <Col span={24} md={12}>
                                <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 25 }}>Donation Package</p>
                            </Col>
                            <Col span={24} md={12}>
                                <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                            </Col>
                        </Row>
                        {searchData.length > 0 ? (
                            <div>
                                <Row>
                                    {searchData.map((item) => {
                                        if (item) {
                                            const isInCart = cartItems.some(cartItem => cartItem.id === item.package_id);

                                            return (
                                                <Row style={{ width: '100%', marginBottom: 20 }} key={item.package_id}>
                                                    <Col xs={24} sm={24} md={10} lg={13} xl={13} className='add-padding-desktop' style={{ textAlign: 'right', padding: '30px 2px' }}>
                                                        <ImageCombiner imageUrls={item.logos} />
                                                    </Col>
                                                    <Col xs={24} sm={24} md={14} lg={11} xl={11} style={{ textAlign: 'left' }}>
                                                        <div className="five columns">
                                                            <section className="entry-content">
                                                                <h1 className="entry-title">{item.name}</h1>
                                                                <div className="entry-excerpt" style={{ display: 'block',marginBottom:10 }} dangerouslySetInnerHTML={{ __html: item.description.substring(0, 300) + " ..." || "" }}>
                                                                </div>
                                                                <a onClick={() => showDetailsModal(item.package_id, item.name, item.description, item.amount, item.logos)} >Show More</a><br />
                                                                Amount: <b>${item.amount}</b><br />
                                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                                                    <Button
                                                                        onClick={() => updateCount(item.package_id, Math.max(1, item.count - 1))}
                                                                        disabled={isInCart}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <span style={{ margin: '0 10px' }}>{item.count}</span>
                                                                    <Button
                                                                        onClick={() => updateCount(item.package_id, item.count + 1)}
                                                                        disabled={isInCart}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                                {isInCart ? (
                                                                    <Button size='default' type="danger" onClick={() => removeFromCart(item.package_id)} style={{ cursor: 'pointer', marginTop: 5 }}>
                                                                        Remove from Cart
                                                                    </Button>
                                                                ) : (
                                                                    <Button size='default' type="primary" onClick={() => {
                                                                        addToCart(item.package_id, item.name, item.amount, item.organisations, item.count);
                                                                    }} style={{ cursor: 'pointer', marginTop: 5 }}>
                                                                        Add to Cart
                                                                    </Button>
                                                                )}
                                                            </section>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            );
                                        }
                                    })}
                                </Row>
                            </div>
                        ) : (
                            <Row style={{ justifyContent: 'center' }}>
                                <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Coming Soon</p>
                            </Row>
                        )}
                    </Col>
                    <Col xs={0} sm={0} md={2} lg={2}></Col>
                </Row>
            </div>
            <Modal
                type="primary"
                title="Package Details"
                width={"80%"}
                open={warningModal}
                footer={null}
                onCancel={handleModalCancel}
            >
                <div className="project-modal">
                    <Col xs={24} sm={24} md={24} lg={24}>
                    <Row style={{ justifyContent: 'center' }}>
                            <ImageCombiner imageUrls={packageLogos} />
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Melodrama", fontSize: 35, marginBottom: 0 }} align="center">{packageName}</p>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 20, fontStyle: 'italic', marginBottom: 0 }} align="center">
                                <div class="entry-meta">
                                    <span class="entry-date">Price : ${packageAmount}</span>
                                </div>
                            </p>
                        </Row><br />
                        <Row style={{ justifyContent: 'center' }}>
                            <p style={{ fontFamily: "Arial", fontSize: 15, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: packageDesc != "" ? packageDesc : "" }}></p>
                        </Row>
                    </Col>
                </div>
            </Modal>
        </div>
    );
};

export default DonationPackageList;
