import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Button } from '../../../components/buttons/buttons';
import { PageHeader } from '../../../components/page-headers/page-headers';
import { Main, CardToolbox } from '../../styled';
import FeatherIcon from 'feather-icons-react';
import { Table, Form, Modal, notification, Input } from 'antd';
import axios from 'axios';

import ContactTable from './ContactTable';


function ContactListDataTable() {

  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });

  const [type, setType] = useState(true);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [editvisible, setEditVisible] = useState(null);
  const [eventID, setFundID] = useState(null);
  const [contactData, setContactData] = useState([])
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    if (isAuthenticate) {
      getAllContacts();
    }
  }, []);

  const getAllContacts = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/get-contact-us`)
      .then((response) => {
        //alert(JSON.stringify(response.data));
        setContactData(response.data);
        console.log("response.data :", response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  

  const { isFbAuthenticate } = useSelector(state => {
    return {
      isFbAuthenticate: state.fb.auth.uid,
    };
  });


  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={"Contact US "}
        />
      </CardToolbox>

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <ContactTable contactData={contactData} />
          </Col>
        </Row>
      </Main>      

    </>          
  );
}

ContactListDataTable.propTypes = {
  match: PropTypes.object,
};
export default ContactListDataTable;
