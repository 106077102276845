import actions from './actions';
import userServices from '../../../services/userServices';
const {
  fbLoginBegin,
  fbLoginSuccess,
  fbLoginErr,
  fbLogOutBegin,
  fbLogOutSuccess,
  fbLogOutErr,
  fbSignUpBegin,
  fbSignUpSuccess,
  fbSignUpErr,
} = actions;

const fbAuthLogin = data => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    try {
      await dispatch(fbLoginBegin());
      const data1=await fb.auth().signInWithEmailAndPassword(data.email, data.password);
      //if(data1)
      // {
      //   const response  = await userServices.createToken(data);
      //   console.log('data1',data1.uid,data);
      // }
      await dispatch(fbLoginSuccess(data));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLogout = logOut => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    try {
      await dispatch(fbLogOutBegin());
      await fb.auth().signOut();
      console.log('Logout : ');
      await dispatch(fbLogOutSuccess());
      // await dispatch(() => {
      //   logOut();
      //   fbLogOutSuccess();
      // });
    } catch (err) {
      await dispatch(fbLogOutErr(err));
    }
  };
};

const fbAuthSignUp = newUser => {
  console.log('data',newUser);
  //return false;
  //return async (dispatch, getState, { getFirebase, getFirestore }) => {
    return async (dispatch, getState, { getFirebase }) => {
    //const db = getFirestore();
    const fb = getFirebase();
    try {
      await dispatch(fbSignUpBegin());
      const resp = await fb.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      console.log('createUserWithEmailAndPassword',resp);
      const uid = resp.user.uid
      let user_data={'uid':uid,'email':newUser.email};
      console.log('user_data',user_data);
      const data = await userServices.updateUID(user_data);

      /*
      await db
        .collection('users')
        .doc(resp.user.uid)
        .set({
          ...newUser,
        });
        */
      await dispatch(fbSignUpSuccess());
    } catch (err) {
      await dispatch(fbSignUpErr(err));
    }
  };
};

const fbAuthLoginWithGoogle = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.GoogleAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLoginWithFacebook = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.FacebookAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

export { fbAuthLogin, fbAuthLogout, fbAuthSignUp, fbAuthLoginWithGoogle, fbAuthLoginWithFacebook };
