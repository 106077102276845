import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, message, DatePicker, Button, TimePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';//VerticalFormStyleWrap
import moment from 'moment';
import axios from 'axios';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';

const { Dragger } = Upload;
const { TextArea } = Input;

const EditEvent = ({ visible, onCancel, Id, oldImage }) => {

  const dispatch = useDispatch();


  const { isAuthenticate } = useSelector(state => {
    return {
      isAuthenticate: state.fb.auth.uid
    };
  });


  const [form] = Form.useForm();
  const formData = new FormData();
  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    title: '',
    description: '',
    link: '',
    image: '',
    status: '',
    isLoading: false
  });

  const handleImageChange = (e) => {
    // console.log('this is e')
    console.log("e.target.files", e.target.files);
    //console.log(e.target.name)
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          message.error('Invalid image format', process.env.REACT_APP_NOTIFICATION_DELAY)
          return false;
        }
      }
      setImage(e.target.files[0]);
    } else {
      setImage("");
    }
  }



  const [description_data, SetDescription] = useState("")
  const [imageModalVisiable, setImageModalVisiable] = useState(false);
  const [image, setImage] = useState('');

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  useEffect(async () => {
    sessionStorage.removeItem('tab-index');

    if (Id) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get-select-feed/${Id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch');
        }

        const data = await response.json();

        if (data && data.length > 0) {
          setEditData(data[0]);
          setShowForm(true);
        } else {
          console.error('No data found for ID:', Id);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  }, [Id]);




  const handleDropdownChange = (value, name) => {
    // return;
    setState({
      ...state,
      [name]: value,
    });
  }



  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);



  const [descriptionlength, SetDescriptionLength] = useState(0);


  const [ArtistList, setArtistList] = useState([]);
  const [ArtworkList, setArtworkList] = useState([]);
  const [editData, setEditData] = useState(null);
  const [ShowForm, setShowForm] = useState(false);



  const [t1, setT1] = useState(null);
  const [t2, setT2] = useState(null);

  const fileobj = [];


  const showModal = () => {
    setImageModalVisiable(true);
  }
  const onProfileCancel = () => {
    setImageModalVisiable(false);
  }



  const handleOk = (values) => {
    const date_active = moment(values.date_active).format('YYYY-MM-DD');
    const date_expiry = moment(values.date_expiry).format('YYYY-MM-DD');

    let post_data = { feed_id: Id, ...values, create_type: editData.create_type, old_Image: editData.image, date_active: date_active, date_expiry: date_expiry };

    if (isAuthenticate !== process.env.REACT_APP_ADMIN_UID) {
      post_data = { feed_id: Id, ...values, create_type: editData.create_type, old_Image: editData.image, status: editData.status, date_active: date_active, date_expiry: date_expiry };
    }


    // console.log(state)
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    const formData = new FormData();
    if (image) {
      formData.append('files', image);
    }

    formData.append('data', JSON.stringify(post_data))


    axios.post(`${process.env.REACT_APP_API_URL}/update-feed`, formData)
      .then((response) => {
        if (response.data.status == true) {
          alert("The feed updated successfully.");
          //window.location.reload();
          //handleCancel();
          onCancel();
        } else {
          setButton(false);
          setLoading(false);
          alert("Uanble to update the feed");
        }
      })
      .catch((error) => {
        console.log("Error :", error)
      })
  };




  const handleCancel = () => {
    onCancel();
  };




  return (

    <>

      <Modal
        type={state.modalType}
        title="Edit Feed"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          {ShowForm == true &&
            <>
              <FormValidationWrap>
                <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">
                  <Form.Item name="title" label="Title"
                    rules={[{ required: true, message: 'Please input feed title' }]}
                    initialValue={editData ? editData.title : ''}
                  >
                    <Input.TextArea rows={2} placeholder="" name="title" />
                  </Form.Item>

                  <Form.Item name="description" label="Description"
                    initialValue={editData ? editData.description : ''}
                  >
                    <TextArea rows={3} type='text' name='description' />
                  </Form.Item>

                  {editData.create_type == "import" ?
                    <>
                      <Form.Item name="image" label="Image URL"
                        initialValue={editData ? editData.image : ''}
                      >
                        <Input type='text' name='image' />
                      </Form.Item>
                    </>
                    :
                    <Form.Item name="image" label="Image"
                    >
                      <Input type='file' name='image' onChange={handleImageChange} />
                    </Form.Item>
                  }

                  <Form.Item name="link" label="Link"
                    rules={[{ required: true, message: 'Please input url' }]}
                    initialValue={editData ? editData.link : ''}
                  >
                    <Input placeholder="url" name="url" />
                  </Form.Item>

                  <Form.Item name="date_active" label="Create Date"
                    rules={[{ required: true, message: 'Please input date' }]}
                    initialValue={editData ? moment(editData.date_active, dateFormat) : ''}
                  >
                    <DatePicker format={dateFormat}
                      value={moment(editData.date_active, dateFormat)}
                      defaultValue={moment(editData.date_active, dateFormat)}
                    />
                  </Form.Item>

                  <Form.Item name="date_expiry" label="Expiry Date"
                    rules={[{ required: true, message: 'Please input expiry date' }]}
                    initialValue={editData ? moment(editData.date_expiry, dateFormat) : ''}
                  >
                    <DatePicker format={dateFormat}
                      value={moment(editData.date_expiry, dateFormat)}
                      defaultValue={moment(editData.date_expiry, dateFormat)}
                    />
                  </Form.Item>

                  <Form.Item name="tags" label="Tags"
                    rules={[{ required: true, message: 'Please input tags' }]}
                    initialValue={editData ? editData.tags : ''}
                  >
                    <Input placeholder="tags" name="tags" />
                  </Form.Item>

                  {/* <Form.Item name="location" label="Location"
                    rules={[{ required: true, message: 'Please input location' }]}
                    initialValue={editData ? editData.location : ''}
                  >
                    <Input placeholder="Location" name="location" />
                  </Form.Item> */}


                  {isAuthenticate === process.env.REACT_APP_ADMIN_UID ?
                    <>
                      <Form.Item name="status" label="Status"
                        rules={[{ required: true, message: 'Please input status' }]}
                        initialValue={Number(JSON.parse(editData.status))}
                      >
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                          onChange={(value) => handleDropdownChange(value, 'status')}
                        >
                          <Option key={1} value={1}>Active</Option>
                          <Option key={0} value={0}>Inactive</Option>
                        </Select>
                      </Form.Item>
                    </>
                    : ""}


                  <Form.Item>

                    <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                      Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                    </Button>
                    {' '}
                    <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                      Cancel
                    </Button>
                  </Form.Item>
                </Form>
              </FormValidationWrap>
            </>}

        </div>
      </Modal>
    </>
  );
};

EditEvent.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default EditEvent;
