
import React from 'react';
import { Card, Avatar, Tag, Typography } from 'antd';
import { FireOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
//import 'antd/dist/antd.css'; // Import Ant Design styles by default

const { Meta } = Card;

const VideoCard = ({ event_id,title, description, image,  userImage }) => {
  return (
    <Card className='card'
      hoverable
      cover={<img alt={title} src={image} />}
      style={{ width: 300, margin: '16px 0px' }}
    >
      {/* {status === 'trending' && (
        <Tag icon={<FireOutlined />} color="red" style={{ position: 'absolute', top: 16, left: 16 }}>
          Trending This Week
        </Tag>
      )}
      {status === 'almostFunded' && (
        <Tag icon={<CheckCircleOutlined />} color="blue" style={{ position: 'absolute', top: 16, left: 16 }}>
          Almost Funded
        </Tag>
      )} */}
      <Link to={`/event-details/${event_id}`}>
      <Meta
        //avatar={<Avatar src={userImage} />}
        title={title}
        description={description}
      />
      </Link>

    </Card>
  );
};


export default VideoCard;
