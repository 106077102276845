const actions = {
    CARTPERCENTAGE_BEGIN: 'CARTPERCENTAGE_BEGIN',
    CARTPERCENTAGE_SUCCESS: 'CARTPERCENTAGE_SUCCESS',
    CARTPERCENTAGE_ERR: 'CARTPERCENTAGE_ERR',
  
    cartPercentageBegin: () => {
      return {
        type: actions.CARTPERCENTAGE_BEGIN,
      };
    },
  
    cartPercentageSuccess: data => {
      return {
        type: actions.CARTPERCENTAGE_SUCCESS,
        data,
      };
    },
  
    cartPercentageSendErr: err => {
      return {
        type: actions.CARTPERCENTAGE_ERR,
        err,
      };
    }
  };

  
  
  export default actions;